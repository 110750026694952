.sphinxsearch {
	width: 100%;
	right: 0;
	left: auto;
	position: absolute;
	top: 100%;
	margin-top: 1px;
	z-index: 99999;
	background: $wh;
	border-radius: 0;
	box-shadow: 0 0 10px $lg;
	& > a {
		cursor: pointer;
		display: inline-block;
		width: 100%;
		padding: 10px 20px;
		color: initial;
		line-height: 17px;
		position: relative;
		@include transition(all 0.2s  ease-in-out); 
		&:first-child {
			border-top: none;
		}
		&:last-child {
			border-bottom: none;
		}
		img {
			float: left;
			margin: 0 10px 0 0;
			padding: 5px;
		}
	}
	.categories, .products {
		span {
			display: block;
			padding: 10px 0;
			font-size: 14px;
			text-align: center;
			background: #eee;
		}
	}
	.categories {
		a {
			text-transform: uppercase;
			text-indent: 10px;
			@include transition(all 0.2s  ease-in-out); 
		}
	}
}


.product_sp {
	height: 60%;
	color: #666;
	text-align: left;
	font-size: 13px;
	margin-left: 75px;
	@include transition(all 0.2s  ease-in-out); 
}

.price_sp {
	width: calc(100% - 89px);
	font-size: 14px;
	font-weight: 600;
	position: absolute;
	bottom: 10px;
	left: 80px;
	padding-left: 22px;
	display: flex;
	flex-direction: column;
	.price {
		font-weight: 600;
	}
	.old {
		text-decoration: line-through;
		margin-right: 5px;
		font-size: 13px;
		color: $lg;
	}
}

.sphinx-viewall {
	font-weight: bold;
	text-align: center;
	padding: 15px 0;
	height: auto;
	&:hover {
		background: $white;
	}
	#view-all {
		background: $theme-color;
		color: $white;
		padding: 4px 25px;
		display: inline-block;
		height: 44px;
		font-weight: 600;
		line-height: 36px;
		@include transition(all 0.2s  ease-in-out); 
		span {
			display: flex;
		}
	}
	&-no-res {
		font-weight: bold;
		text-align: center;
		height: 30px;
		color: $black;
		width: 100%;
		line-height: 30px;
	}
}

#input-search + .sphinxsearch {
	left: 15px;
}
.sphinxsearch a{
	&:hover, &:focus, &:active {
		color:$tc2;
		.product_sp {
			color:$tc2;
		}
	}
}
@media(max-width:430px) {
	.sphinxsearch {
		width: 100%;
	}
}