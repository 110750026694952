@media (min-width: 1921px) {
	.container {
		padding-left: 16px;
		padding-right: 16px;
		max-width: 1812px;
		position: relative;

		&.harakteristiki {
			padding-left: 108px;
			padding-right: 108px;
		}
	}
	.gift-certs {
		margin-left: auto;
		margin-right: auto;
		max-width: 1812px;
	}
	.downloads-content {
		&.is_stuck {
			right: 17.6%;
		}
	}
	.header-menu {
		#menu {
			.pc_menu {
				top: 80px;
			}
		}
	}
}
@media (max-width: 1610px) {
	.header-bottom {
		.worktime_phones {
			#topcontacts {
				flex-direction: column;
			}
			.topcontacts {
				width: 100%;
				justify-content: flex-end;

				&.phone {
					.media-body {
						margin-right: 0;
					}
				}
			}
		}
	}
	.holiday_works {
		bottom: -20px;
	}
}
@media (max-width: 1540px) {
	.product-info {
		.image-container {
			max-width: 498px;
		}
	}
}
@media (max-width: 1440px) {
	.header-bottom {
		.worktime_phones {
			#topcontacts {
				flex-wrap: wrap;
				justify-content: flex-end;
			}
			.topcontacts {
				&.phone {
					.media-body {
						margin-right: 0;
					}
				}
				&.worktime {
					margin-bottom: 4px;
				}
			}
		}
		.holiday_works {
			bottom: -18px;
		}
	}
	#sidebar-left {
		width: 33.33333%;
	}
	#sidebar-left + #sidebar-main {
		width: 66.66667%;
	}
	.widget-products {
		.tcarousel {
			.owl-stage.active {
				.owl-item {
					&:nth-child(4n+4) {
						margin-right: 0 !important;
					}
					&:nth-child(5n+5) {
						margin-right: 16px !important;
					}
				}
			}
		}
	}
	#footer {
		.button_red_inv {
			width: 100%;
		}
	}
	.pav-megamenu {
		.navbar-nav {
			& > li {
				& > a {
					padding-right: 24px;
				}
			}
		}
	}

	#products {
		.product-block {
			width: calc(100% / 3 - 11px);
		}
	}
	.product-grid,
	.products-owl-carousel {
		.product-block {
			&:nth-child(4n+4) {
				margin-right: 16px;
			}
			&:nth-child(5n+5) {
				margin-right: 16px;
			}
			&:nth-child(3n+3) {
				margin-right: 0;
			}
		}
	}
	.checkout_cart {
		.order2 {
		    width: 42%;
		}
		.order {
		    width: 50%;
		}
	}
	#buy_form_container {
		.buy_products {
			.pr_cart {
				.name_price {
					.column-name {
						a {
							width: 335px;
						}
					}
				}
			}
		}
	}
	#checkout-form {
		.filled_fields {
			margin-bottom: 46px;
		}
		.button_red_inv {
		    margin-top: 30px;
		}
		h3 {
		    margin-top: 56px;
		}
	}
	#shipping_methods,
	#payment_methods {
		margin: 0;
		h3 {
			margin-top: 48px;
		}
	}
	.confirm_block {
		padding-top: 25px;
	}
	.page-product-special,
	.page-product-search {
		#products {
			.product-block {
				width: calc(100% / 4 - 16px);
			}
		}
	}
	.success {
		&.product-info {
			overflow: scroll;
			overflow-x: hidden;
			@include size(100%,100%);
			left: 0;
			top: 0;
			margin: 0;
			padding: 4% 2%;
		}
	}
	.gift-certs {
		margin-left: 0;
		margin-right: 0;
	}
	.header-menu {
		#menu {
			.pc_menu {
				& > .container {
					.nav {
						flex-wrap: wrap;
					}
				}
			}
		}
	}
	.lvl1 {
		width: calc(100% / 3 - 16px);
	}
	.product-info {
		#product {
			max-width: 294px;
		}
	}
}

@media (max-width: 1399px) {
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.harakteristiki {
		& > .downloads-content {
			display: none;
		}
	}
	.harakteristiki-list {
		ul {
			a {
				font-size: 16px;
				padding-right: 0;
				padding-left: 0;
			}
		}
		.download-tab {
			display: block;
		}

		&.is_stuck {
			padding: 0 64px;

			ul {
				overflow: hidden;
				max-width: 720px;
			}
		}
	}
	.kit_block {
		.producs_block {
			.producs_block_left {
				margin-right: 16px;
				width: calc(66% - 16px);
			}
			.kit_total {
				width: 34%;
				padding: 35px 32px 35px 32px;

				.top {
					.title {
						white-space: nowrap;
					}
				}
			}
		}
	}
	.tabs_block {
		margin-right: 0;
		width: 100%;

		.downloads-content {
			display: block;
			position: unset;
			max-width: 100%;
			padding: 32px 56px 56px 32px;
		}
	}
	.product-info {
		.image-container {
			max-width: 350px;
			width: 100%;
			max-height: 550px;

			.image-additional {
				.item {
					a {
						@include size(60px, 60px);
					}
				}
			}
		}
		.image {
			padding-top: 15%;
		}
	}
	.image_block {
		#image-additional {
			max-width: 350px;
			width: 100%;
		}
	}
}

@media (max-width: $screen-md-max) { // 1199
	.product-info {
		.product-info-bg {
			flex-direction: column;
		}
		.image-container {
			margin-right: 0;
			margin-bottom: 40px;
			max-width: 100%;
			width: 100%;
			max-height: unset;

			.image_block,
			.image-thumb {
				background: transparent;
			}
			.image-gallery {
				padding-top: 0;
			}
			.image-additional {
				.item {
					a {
						@include size(80px, 80px);
					}
				}
			}
		}
		.product_stik {
			width: 100%;
		}
		#product {
			max-width: 358px;
			width: 100%;
		}
		.product-meta {
			.btn-sm,
			.btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
		.product-view {
			h1 {
				margin: 0 0 32px 0;
			}
		}
		.image {
			padding-top: 0;
		}
	}
	.all_cat_block {
		.cat {
			width:calc(100% / 2 - 16px);

			&:nth-child(3n + 3) {
				margin-right: 16px;
			}
			&:nth-child(2n + 2) {
				margin-right: 0;
			}

			.img {
				img {
					max-width: unset !important;
					left: -22%;
				}
			}
		}
	}
	.backdrop {
		top: 144px;
	}
	.widget-products {
		.tcarousel {
			.owl-stage.active {
				.owl-item {
					&:nth-child(3n+3) {
						margin-right: 0 !important;
					}
					&:nth-child(4n+4) {
						margin-right: 16px !important;
					}
				}
			}
		}
	}
	#buy_form_container {
		.buy_products {
			.pr_cart {
				.name_price {
					.column-name {
						a {
							width: 300px;
						}
					}
				}
			}
		}
	}
	#products {
		.product-block {
			width: calc(100% / 2 - 8px);
		}
	}
	.product-grid,
	.products-owl-carousel {
		.product-block {
			&:nth-child(3n+3) {
				margin-right: 16px;
			}
			&:nth-child(2n+2) {
				margin-right: 0;
			}
		}
	}
	.mfilter-content {
		#mfilter-opts-price-min,
		#mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	#powered {
		.container {
			& > .row {
				margin: 0;
			}
		}
	}

	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}

	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.logo-container {
			display: none;
		}
		.nav-search {
			margin-bottom: 7px;
		}
		.holiday_works {
			right: 65px;
		}
	}
	.refine-search {
		ul, ol {
			li {
				a {
					span {
						line-height: 15px;
						font-size: 12px;
						overflow: hidden;
					}
				}
			}
		}
	}
	.footer-center {
		.main-footer {
			margin: 0;
		}
		.box {
			margin-right: 16px;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	.fancybox-nav {
		span {
			visibility: visible;
		}
	}

	.page-category {
		#sidebar-left {
			width: 32%;
		}
		#sidebar-main.col-md-9 {
			width: calc(70% - 24px);
		}

	}
	.checkout_cart {
		.order2 {
		    width: 40%;
			padding: 0;
		}
		.order {
		    width: 55%;
		}
	}
	#checkout-form {
		.button_red_inv {
			width: 100%;
		}
	}

	.pav-megamenu {
		.navbar-nav {
			& > li {
				& > a {
					font-size: 16px;
					line-height: 20px;
					padding-right: 8px;
				}
			}
		}
	}

	.header-menu {
		.logo-container {
			display: block;
			margin-right: 15px;
		}
	}
	.featured-category {
		.owl-stage-outer {
			overflow: unset;
		}
	}
	.newsletter-container {
		padding: 32px 0;

		.newsletter-block {
			max-width: 436px;
			margin-right: 22px;
		}
		.img {
			img {
				max-width: unset !important;
				width: 120%;
				margin-top: 10px;
			}
		}
	}
	#footer {
		ul, ol {
			li {
				margin-bottom: 16px;

				&.ft-addr,
				&.ft-wt {
					span {
						white-space: break-spaces;
					}
				}
				&.ft-wt {
					flex-direction: column;
				}
			}
		}
	}
	.gift-certs {
		padding: 56px 0 56px 64px;
		background-size: 80%;

		.description {
			max-width: 515px;
		}
	}
}

@media (max-width: $screen-sm-max) {
	.pav-megamenu {
		.navbar-nav {
			& > li {
				&  > a {
					font-size: 15px;
				}
			}
		}
	}
	.content_bottom_cat {
		padding: 0;

		.viewed-container {
			padding-left: 32px;
			padding-right: 32px;
		}
	}
	.header-menu {
		padding-bottom: 24px;

		& > .container {
			.main-menu {
				order:1;

				.canvas-menu {
					@include size(52px,48px);
					margin: 0;
					padding: 0;
					border: none;
					background: $tc;
				}
			}
			.logo-container {
				order:2;
				margin-right: 24px;
			}
			.nav-search {
				order:3;
			}
			.headercart {
				order:4;
			}
			& > div {
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.button_menu_cat {
			padding-right: 15px;

			.red_button {
				padding: 14px 22px;
				width: max-content;
			}
		}
	}
	.all_cat_block {
		.cat {
			margin-right: 0;
			margin-bottom: 32px;

			.img {
				img {
					left: 0;
					height: auto;
					width: 100%;
				}
			}
			&:nth-child(-n + 4),
			&:nth-last-child(-n+4) {
				width: calc(100% / 2 - 8px);
			}
			&:nth-child(3) {
				width: 100%;

				.img {
					img {
						bottom: -180px;
						max-width: 100% !important;
					}
				}
			}
			&:nth-child(1),
			&:nth-child(4) {
				margin-right: 16px;
			}
		}
	}
	.mfilter-box {
		.box-content {
			.mfilter-heading {
				.mfilter-head-icon {
					@include size(40px,20px);
					text-align: right;
					justify-content: flex-end;
				}
			}
		}
	}
	.order1.is_stuck + div{
		display: none !important;
	}
	#sidebar-left + #sidebar-main {
		width: 100%;
		padding: 0;
	}
	.product-grid,
	.products-owl-carousel {
		.product-block {
			&:nth-child(2n+2) {
				margin-right: 16px;
			}
			&:nth-child(3n+3) {
				margin-right: 0;
			}
		}
	}
	.product-filter .pull-left + .pull-left {
		margin-left: 0;
	}
	.product-filter {
		background: $wh;
		padding: 16px;
	}
	#products {
		.product-block {
			width: calc(100% / 3 - 11px);
		}
	}
	#sidebar-main.col-md-9 {
		padding: 0;
	}
	.header-bottom {
		.worktime_phones {
			.topcontacts {

				&.phone,
				&.worktime {
					.media-body {
						span {
							font-size: 15px;
							line-height: 22px;
							font-weight: 400;
							margin: 0;

							a {
								color: $txt_black;
							}
						}
					}
				}
				&.worktime {
					order: 1;
				}
				&.phone {
					.media-body {
						span {
							font-weight: 600;
						}
					}
				}
			}
			.holiday_works {
				right: auto;
			}
		}
	}
	#cart {
		padding: 0;
		margin-left: 23px;
	}
	.product-compare-header {
		padding: 0;
	}
	.widget-images {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
		.box {
			width: 33.3333%;
		}
	}
	#pavo-footer-center {
		.container {
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			.product-grid .product-block {
				width: calc(100% / 3 - 13px);
				margin: 5px;
			}
			width: calc(100% / 3 - 13px);
		}
	}
	.h1_container {
		padding: 0 0 56px 0;
	}
	body {
		&.page-error-not_found {
			#content {
				padding: 0 16px;
			}
		}
		.mfilter-free-container {
			.category_filter {
				.tree-menu {
					ul {
						li {
							a {
								padding: 11px 16px 11px 16px;
								margin-bottom: 8px;
							}
						}
					}
				}
			}
		}
		.mfilter-free-container {
			top: 0 !important;
			@include size(100%,100%);
			padding: 64px 32px 40px 32px;
			@include box-sizing(border-box);
			overflow: auto !important;
			right: auto;
			left: 0;
			margin-left: -100%;
			border: none;
			background: $lg_bg;
			&.mfilter-direction-rtl {
				margin-right: -222px;
			}
			#mfilter-free-container-0 {
				padding: 30px 24px !important;
				overflow: scroll !important;
				background: $wh;
				max-height: inherit !important;
			}
			.category_filter {
				background: $wh;
			}
			.button_red_inv.visible-sm {
				width: 100%;
				text-align: center;
				margin-top: 24px;
			}
			.close_filter {
				position: absolute;
				right: 0;
				top: 0;
				padding: 20px 44px;
				svg{
					fill: $bk;
				}
			}
			.mfilter-box {
				margin-bottom: 5px;
			}
			.mfilter-free-button {
				background: $theme-color;
				margin: 0 220px 0 -45px;
				border: 1px solid $bc;
				padding: 9px 5px;
				top: -1px;
				@include size(33px,23px);
				display: none !important;
				&:before {
					content: '\f1f3';
					font-family: $fa;
					color: $white;
					font-size: 28px;
					line-height: 23px;
					padding: 3px;
					left: -1px;
					position: relative;
				}
			}
			#mfilter-opts-price-min,
			#mfilter-opts-price-max {
				width: 65px;
				min-width: 65px;
				padding: 9px 7px;
			}
		}
	}
	.pagination {
		& > li {
			& > a,
			& > span {
				margin-bottom: 8px;
			}
		}
	}
	.col-md-12 {
		.refine-search {
			ul, ol {
				li {
					width: calc(25% - 20px);
				}
			}
		}
	}
	.product-info {
		#product {
			margin-bottom: 0;
		}
		.product-info-bg {
			flex-direction:column;

			.product-view {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				h1 {
					margin-top: 8px;
					margin-bottom: 16px;
				}
			}
			.image-container {
				padding: 0;

				.image_block {
					#image-additional {
						position: relative;
					}
				}
			}
			.product_stik {
				padding: 0;
			}
		}
		.other-dost {
			span {
				display: block;
			}
		}
		.tab-content {
			.product-grid {
				&.no-scroll {
					.product-block {
						margin: 4px 2px;
						width: calc(100% / 3 - 7px);
					}
				}
			}
		}
	}
	#offcanvasmenu {
		.megamenu {
			.canvas-menu-list {
				.nav {
					&:before, &:after {
						content: unset;
					}
				}
			}
			&.mobile-time-works {
				padding-top: 24px;

				.time-mob,
				.phone-mob,
				a {
					color: $txt_black;
				}
				.phone-mob {
					a {
						display: block;
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
					}
				}
				li {
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.holiday_works {
				position: unset;
				text-align: left;
				font-size: initial;
			}
			& > .dropdown {
				.dropdown-toggle {
					display: none;
				}
				& > a {
					font-size: 20px;
					line-height: 24px;
					margin-bottom: 24px;
				}
			}
			&.navbar-nav > li {
				& > a {
					border: none;
					@include box-shadow(none);
					text-transform: none;
					padding: 0;
					margin-bottom: 16px;
				}
				&:last-child {
					a {
						margin-bottom: 0;
					}
				}
			}
			.parent.dropdown + li {
				padding-top: 16px;
			}
		}
	}
	.lvl1 {
		width: calc(100% / 2 - 16px);

		&:nth-child(2n + 2) {
			margin-right: 0;
		}
	}
	#footer {
		.logo-store {
			padding: 0;
		}
	}
	#powered {
		.powered_block {
			display: flex;
			flex-direction: column;
		}
		.inner {
			margin-bottom: 32px;
		}
		.block_dir {
			width: 50%;
			ul {
				max-width: initial;
			}
			.link-direktor {
				width: 100%;
				border: 1px solid $tc;
				line-height: 20px;
			}
		}

	}
	.kit_block {
		.producs_block {
			.producs_block_left {
				width: calc(57% - 16px);
			}
			.kit_total {
				width: 43%;
			}
		}
	}
	.pr_img_mobile {
		margin-bottom: 33px;
		img {
			max-height: 344px;
		}
	}
	.harakteristiki {
		padding-top: 32px;
	}
	.harakteristiki-list {
		&.is_stuck {
			padding: 0 32px;

			ul {
				li {
					margin-right: 0;
					a {
						padding: 12px 8px 10px 8px;
					}
				}
			}
		}
		.nav-product-info {
			.info {
				padding: 25px 0;

				.price {
					.price-new {
						font-size: 16px;
						line-height: 20px;
					}
				}
				.btn {
					#button-cart {
						min-height: 34px;

						span {
							font-size: 14px;
							line-height: 18px;
							font-weight: 300;
							padding: 8px 12px;
						}
					}
				}
			}
		}
	}
	.tabs_block {
		.tab-item {
			margin-bottom: 32px;
		}
	}
	.kit_block {
		padding-top: 56px;
		padding-bottom: 56px;

		.producttabs {
		    padding: 80px 0;
		}
	}
	.content_bottom_pr {
		.producttabs  {
			padding: 56px 0 0 0;
		}
	}
	.tabs_carusel {
		padding-top: 32px;
	}
	.page-category {
		#sidebar-main.col-md-9 {
		    width: 100%;
		    padding: 0;
		}
	}
	.filter-right {
		display: flex;
		& > div {
			margin-right: 16px;
		}
		.limit {
			width: 100%;
			margin-right: 0;

			.selectric-wrapper {
				float: right;
			}
		}
	}
	.checkout_cart {
		flex-direction: column;

		#checkout-form {
			.indicators {
				display: none;
			}
		    .w2, .w1 {
		    	max-width: 100%;
		    }
		    #input-payment-telephone {
		    	max-width: 284px;
		    }
		}
		.order2 {
			width: 100%;
			order:2;
		}
		.order {
			max-width: inherit;
			width: 100%;
			order:1;
			margin:0;
			position: relative;

			.order1 {
				position: relative !important;
			}
		}

	}
	#buy_form_container {
		.buy_products {
			.pr_cart {
				.name_price {
					.column-name {
						a {
							width: 460px;
						}
					}
				}
			}
		}
	}
	.page-product-special,
	.page-product-search {
		#products {
			.product-block {
				width: calc(100% / 3 - 16px);
			}
		}
	}
	.content_bottom_pr {
		&.none_kit {
			.producttabs {
				padding-top: 56px;
			}
		}
	}
	.tab-content > .tab-pane {
		border: none;
	}
	.breadcrumb {
		-webkit-flex-wrap: wrap;
   		-ms-flex-wrap: wrap;
   		flex-wrap: wrap;
	}
	.success {
		&.product-info {
			.go_shop {
				margin-bottom: 16px;
			}
		}
	}
	.product-compare-header {
		#compare-right {
			.badge {
				top: 7px;
			}
		}
	}
	.gift-certs {
		padding: 40px 0 45px 32px;
		background-size: 100%;
		background-position-x: 90px;

		h2 {
			margin-bottom: 16px;
			font-size: 26px;
			line-height: 32px;
		}
		.description {
			font-size: 18px;
			line-height: 28px;
			max-width: 404px;
		}
	}
	.newsletter-container {
		padding: 26px 0;

		.newsletter-block {
			max-width: 344px;
			margin-right: 16px;

			.text {
				font-size: 18px;
				line-height: 28px;
			}
			.checkbox_custom {
				label {
					white-space: nowrap;
				}
			}
		}
		.img {
			img {
				width: 130%;
			}
		}
	}
	.product-col-category {
		.product-col-cat-carousel {
			display: block;
		}
	}
}

@media (max-width: 991px) and (min-width: 768px){
	body.information-direktoru {
		.main-wrap{
			padding: 0 15px;
		}
	}
	.page-home {
		.producttabs {
			padding-top: 80px;
		}
	}
	.product-view {
		max-width: unset;
	}
	.product-block {
		.price {
			&.no_stock {
				& > span {
					font-size: initial;
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	h1, .h1, h2, .h2, h3, .h3 {
		font-size: 20px;
		line-height: 26px;
	}


	.all_cat_block {
		padding-bottom: 104px;

		.cat {
			margin-bottom: 16px;
			margin-right: 0 !important;
			width: 100% !important;;

			.img {
				img {
					bottom: 0 !important;
				}
			}
		}
	}
	.page-information{
		.main-columns {
			padding-bottom: 80px;
		}
	}
	.vm--modal{
		max-width: 96%;
		left: 2% !important;
	}
	.paging {
		flex-direction: column;
		.paging_block {
			width: 100%;
		}
		.next {
			width: 100%;
			.button_red_inv {
				width: 100%;
			}
		}
	}
	.checkout-buy {
		#content {
			padding: 0 16px;
			& > .row {
				margin: 0;
			}
			.h1_container {
				padding: 0;
				h1 {
					padding-bottom: 32px;
				}
			}
		}
		.checkout_cart {
		    margin: 0 -18px;

			.order2 {
				#checkout-form {
					h3 {
						margin: 32px 0;
					}
					.form-group {
						margin-bottom: 24px;
					}
				}
			}
		    .order {
				.order1 {
					padding: 0;
					margin-bottom: 0;

					#coupon_form {
						.form-group {
							flex-direction: column;

							#input-coupon {
								width: 100%;
							}
							#button-coupon {
								margin-top: 8px;
								@include size(100%,44px);
								max-width: inherit;
							}
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		    .buy_block2 {
		    	margin: 0;
		    }
		    #checkout-form {
		    	padding: 0;

		    	.button_red_inv {
		    		margin-top: 56px;
		    	}
		    	.adress_block {
					.form-group {
						width: 100%;
						padding: 0;
					}
				}
		    }
		    .confirm_block {
				.checkbox_custom {
					margin-bottom: 8px;
				}
			}
		}
	}
	#buy_form_container {
		.buy_products {
			.pr_cart {
				flex-direction: column;
				padding-top: 8px;
				border-top: 1px solid $lg2;

				&:first-child {
					padding-top: 8px;
					border-top: 1px solid $lg2;
				}
				.name_price .column-name a,
				.name_price {
					width: 100%;
				}
				.quant_clear {
					width: calc(100% - 80px);
					margin-top: 18px;
					flex-direction: row-reverse;
					align-items: center;
					align-self: flex-end;
					.btn-white {
						display: flex;
					}
					.column-quantity input {
						width: 34px;
					}
				}
			}
		}
	}
	#products {
		.product-block {
			width: calc(100% / 2 - 8px);

			&:nth-child(3n + 3) {
				margin-right: 0;
			}
			&:nth-child(2n - 2) {
				margin-right: 0;
			}
			.price {
				&.no_stock {
					& > span {
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	#powered {
		.logo-store {
			text-align: center;
		}
		ul.links {
			margin-bottom: 40px;
		}
		.inner {
			flex-wrap: wrap;
			.copyright {
				order: 2;
				margin-bottom: 15px;
			}
			.payment {
				order: 1;
				.payment-img {
					max-width: 100%;
				}
			}
		}
	}
	.content_bottom_pr {
		&.container {
			padding-top: 30px;
		}

		.producttabs, &.none_kit {
			padding-top: 0;
		}
	}
	.widget-products {
		.tab-header {
			margin-bottom: 24px;
			font-size: 20px;
			line-height: 24px;
		}
	}
	.h1_container {
		padding-bottom: 32px;

		&:before {
			content: unset;
		}
		h1 {
			font-size: 20px;
			line-height: 26px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table,
	.shopping-cart-table {
		background: $wh;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;

			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.header-bottom {
		& > .container {
			margin-bottom: 8px;
			height: auto;
		}
		.worktime_phones {
			justify-content: flex-start;
			flex-direction: column;

			#topcontacts {
				justify-content: flex-start;
			}
			.topcontacts {
				&.worktime {
					margin-bottom: 2px;

					.media-body {
						span {
							font-weight: 500;
							font-size: 12px;
							line-height: 14px;
						}
					}
				}
				&.phone {
					.media-body {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
		.holiday_works {
			display: none;
		}
	}
	.shopping-cart-btns {
		text-align: center;

		.btn.pull-left,
		.btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}

	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
				margin: 0 0 16px 0;

				.box-heading {
					margin-bottom: 24px;
				}
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 16px;
	}
	#header-layout {
		padding: 16px 0;
	}
	.header-menu {
		padding: 0;

		& > .container {
			padding: 0 16px;
			justify-content: flex-start;

			& > div {
				height: auto;
			}
			.logo-container {
				@include size(auto,auto);
				margin-right: 0;
				padding: 5.5px 0;

				a {
					display: block;
				}
				svg {
					height: 22px;
				}
			}
			& > .nav-search {
				position: absolute;
				z-index: 999;
				left: -100%;
				top: 77px;
				width: 96%;
				@include transition(all 0.3s  ease-in-out);

				.search_block {
					max-width: 100%;
				}
				&.active {
					left: 2%;
				}
			}
			.main-menu {
				margin-right: 8px;

				.canvas-menu {
					@include size(35px,35px);
					min-height: auto;
					line-height: 0;
				}
			}
			.headercart {
				@include size(100%,auto);
				justify-content: flex-end;
				padding: 3px 0;

				.search_button,
				.product-compare-header,
				#cart {
					margin-left: 8px;
					svg {
						@include size(26px,26px);

						path {
							fill: $txt_black;
						}
					}
				}
				& > .container {
					& > div {
						align-items: center;
					}
				}
			}
		}
	}
	.bg_search {
		display: block;
	}
	.search_button {
		display: flex;
	}
	.banner_home {
		margin-top: 0;
	}
	.homebuilder {
		.panel-title,
		.h2,
		h2 {
			font-size: 20px;
			line-height: 26px;
		}
		.home-categories {
			margin-top: 40px;

			.panel-title {
				margin-bottom: 0;
			}
		}
	}
	.gift-certs {
		margin-top: 40px;
		padding: 40px 16px 184px 16px;
		background-image: url(https://cdn.sani-shop.ru/image/img_template/gift-certs-bg-mob.png);
		background-position: 0 190px;
		background-size: contain;

		.description {
			margin-bottom: 10px;
		}
		h2 {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 26px;
		}
		.link {
			margin-top: 16px;
		}
	}
	.newsletter-container {
		margin-top: 40px;
		flex-direction: column-reverse;

		.newsletter-block {
			max-width: 100%;
			margin-right: 0;

			.h2,
			.text {
				margin-bottom: 16px;
			}
			.tm-login-form {
				input {
					font-size: 20px;
					line-height: 26px;
					height: 26px;
				}
				button {
					min-height: unset;
				}
				svg {
					@include size(24px,24px);
				}
			}
			.checkbox_custom {
				label {
					display: block;
					white-space: normal;
				}
			}
		}
		.img {
			max-width: 432px;

			img {
				max-width: 100% !important;
				width: 100%;
				margin: 0;
			}
		}
	}
	.page-home {
		.producttabs,
		.product-cols {
			padding-top: 40px;
		}
	}
	#footer {
		ol, ul {
			li {
				margin-bottom: 8px;
			}
		}
	}
	#powered {
		padding-top: 0;
		.powered_block {
			flex-direction: column;
			margin-top: 8px;
		}
		.inner {
			width: 100%;
		}
		.block_dir {
			width: 100%;
			margin-top: 16px;
		}

	}
	footer {
		padding-top: 0;

		.ft-topline {
			width: 768px;
		}
	}
	.product-info {
		.sku_rating {
			flex-wrap: wrap;
		}
		.product-price-info {
			flex-direction: column-reverse;
		}
		#product {
			max-width: 100%;
			margin-top: 10px;
			padding: 32px 16px;
			margin-bottom: 32px;

			.price {
				&.detail {
					ul {
						li {
							.price-new {
								font-size: 20px;
								line-height: 26px;
							}
						}
					}
				}
			}
			#button-cart {
				width: 100%;
			}
		}
		.product-info-bg {
			margin-top: 0;
			padding-bottom: 0;

			.image-container {
				#image-additional {
					width: 100%;
				}
			}
			.product-view {
				h1,
				.h1 {
					font-size: 20px;
					line-height: 26px;
				}
				.cart.pull-left {
					margin-bottom: 16px;
				}
			}
		}
		.tab-content {
			margin-bottom: 0;
		}
	}
	.success,
	#request-on-order-form,
	#reserve_form,
	#finded-low-cost-form,
	.modal_div {
		padding: 0;
		.success_bg {
			padding: 10px 16px;
		}
	}
	#request-on-order-form {
		.modal-dialog{
			margin: 8% 4% !important;
		}
		.success_bg {
			padding: 0;
		}
	}
	.kit_block {
		padding: 32px 16px 0 16px;
		.producttabs {
		    padding: 56px 0;
		    margin: 0;
		}
	    .h1 {
	    	margin: 0 0 26px 0;
	    	font-size: 20px;
			line-height: 26px;
	    }
	    .producs_block {
	    	.producs_block_left {
	    		width: 100%;
	    		margin: 0;
	    	}
	    	.kit_total {
	    		padding: 35px 23px;
	    		width: 100%;
	    	}
	    	.product_list_item,
			.product_list_item:last-child{
	    		margin-bottom: 12px;
	    	}
	    }
	}
	.content_bottom_pr {
		.producttabs {
			margin: 0;
			padding: 32px 0;
		}
	}
	.related_pr {
		.v_all,
		.v_all_close {
			display: none;
		}
		.go_cat {
		    display: flex;
		    width: 100%;
		    text-align: center;
		    line-height: 1.3;
		}
	}
	.tabs_carusel {
		padding-top: 40px;
		padding-bottom: 11px;

		&.owl-carousel.owl-drag .owl-item {
			margin: 0 !important;
		}
	}
	.tabs_block {
		padding-top: 16px;

		.title {
			font-size: 20px;
			line-height: 26px;
			margin-bottom: 24px;
		}
		.tab-item{
			&:last-child {
				margin-bottom: 0;
			}
		}
		.table {
			.feat_one {
				border: none;

				&:first-child {
					padding-top:16px;
				}
				.feature {
					width: 100%;

					&:first-child {
						padding-top: 0;
					}

					.text {
						flex-direction: column;
						padding-bottom: 8px;

						div {
							padding-right: 0;

							&:first-child {
								line-height: 20px;
								font-weight: 500;
								margin-bottom: 8px;
							}
						}
					}
				}
			}
		}
	}
	.downloads-content {
		padding: 16px 0;
		@include box-shadow(none);

		.title {
			margin-bottom: 16px;
		}
	}
	#review {
		.title {
			margin-bottom: 8px;
		}
		.review {
			padding: 16px;

			&:last-child {
				margin-bottom: 8px;
			}
		}
	}
	.nav-tabs {
		.item {
			a {
				margin: 0;
			}
		}
	}
	.breadcrumbs {
		padding: 16px;

		.container {
			padding-right: 0;
			padding-left: 0;
		}
	}
	body {
		&[class*="product-special"],
		&[class*="information-"] {
			#content {
				padding: 0 16px 50px 16px;
			}
		}
		&[class*="information-"] {
			#content {
				padding-right: 0;
				padding-left: 0;

				& > .row {
					margin: 0;
				}
			}
		}
		&.product-allproduct {
			.main-columns.container {
				& > .row{
					margin: 0;
				}
			}
		}
		&.product-compare,
		#page .not_found {
			#content{
				padding: 0 16px;
			}
		}
		.mfilter-free-container {
			padding: 64px 0 20px 0;
			.category_filter {
				.tree-menu {
					padding: 32px 16px;
				}
			}
			.close_filter {
				padding: 20px 12px 20px 25px;
			}
		}
	}
	.page-category {
		.producttabs {
			padding-bottom: 80px;
			margin: 0;
		}
		.viewed-container {
			padding-left: 16px;
			padding-right: 16px;

			.tab-header {
				.h2 {
					font-size: 20px;
					line-height: 26px;
				}
			}
		}
	}
	.filtr_toggle {
		font-size: 14px;
		line-height: 18px;
		padding: 5px 12px;
		svg {
			margin-right: 4px;
			width: 12px;
		}
	}
	.selectric {
		height: auto;
		padding: 0;
	}
	.product-filter {
		margin-left: -16px;
		margin-right: -16px;

		.sort {
			margin-right: 0;
		}
		.selectric {
			.label {
				font-size: 14px;
				line-height: 18px;
			}
			.button {
				padding: 6px 4px 2.5px 2.8px;
				@include size(24px,24px);
				&:after {
					@include size(6px,10px);
				}
			}
		}
	}
	.filter-right {
		justify-content: space-between;
		align-items: center;

		#filter-mob-btn {
			margin-right: 8px;
		}
	}
	.selectric-items {
		top: 35px;
	}
	#checkout-form {
		.button_confirm {
			width: 100%;
		}
	}
	.product-search {
		#sidebar-main {
			padding: 0 16px;
		}
		.checkboxes {
			margin-bottom: 10px;
			flex-direction: column;
			.checkbox_custom {
				width: 100%;
				margin: 0;
				label {
					margin: 10px 0;
				}
			}
		}

	}
	.information-contact {
		.main-columns {
			&.container {
				#content,
				fieldset {
					padding: 0;
				}
			}
		}
	}
	.page-product {
		.breadcrumbs {
			li {
				display: none;

				&:nth-last-child(2) {
					position: relative;
					display: block;

					a {
						span {
							margin-left: 10px;
						}
					}
					&:before {
						@include transform(rotate(180deg));
						position: absolute;
						top: 4px;
					}
				}
			}
		}
	}
	.page-product-special,
	.page-product-search {
		#products {
			.product-block {
				width: calc(100% / 2 - 16px);
			}
		}
	}
	.modal-body {
	    padding: 0 16px;
	    .input_file {
	    	width: 100% !important;
	    	text-align: center;
	    }
	}
	.success {
		&.product-info {
			overflow: scroll;
			overflow-x: hidden;
			height: 100%;
			.modal-body,
			.modal {
				margin: 0;
			}
			.success_bg{
				padding: 0;
				.go_shop, .go_cart {
					width: 100%;
				}
			}
			.product_list_item {
				padding-right: 10px;
				padding-left: 10px;
				.image {
					padding: 0;
				}
			}

		}
		&.compare {
			top: 4%;

			.text {
				margin-bottom: 24px;
			}
		}
	}
	#callback_form {
		.modal-dialog {
			top: 2% !important;
			.callback_button {
				width: 100%;
			}
		}
	}
	.harakteristiki {
		margin-top: 10px;
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.harakteristiki-list {
		ul {
			li {
				margin-right: 8px;
			}
			a {
				font-size: 16px;
				line-height: 20px;
				padding: 12px 8px 10px 8px;
			}
		}
		&.is_stuck {
			padding: 0 0 25px 0;
			flex-direction: column-reverse;
			align-items: flex-start;

			.nav-product-info {
				.info {
					padding: 8px 16px 0 16px;
					width: 100%;
					margin-bottom: 10px;

					.img {
						max-width: 61px;
					}
					.price {
						width: 100%;
						text-align: right;
					}
				}
			}
			ul {
				padding: 8px 16px 0 16px;
				max-width: 100%;
			}
		}
	}
	.fancy_holiday_works {
		.fancybox-skin {
			padding: 50px 16px !important;
			.fancybox-inner{
				margin: 0 auto;
				width: auto !important;
			}
		}
	}

	.pagination {
		margin-bottom: 18px;
		& > li {
			a,
			span {
				@include size(40px,40px);
				line-height: 20px;
				font-size: initial;
				margin-bottom: 0;
			}
		}
	}
	.lvl1 {
		margin-right: 0;
		width: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		.img {
			display: none;
		}
		.title {
			margin-bottom: 0;
		}
		.parent {
			width: 100%;

			.link {
				position: relative;
				padding: 0 24px 0 0 !important;

				&:after {
					content: '';
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					@include size(24px,24px);
					padding: 8px 5px;
					color: $text-color;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='17' viewBox='0 0 10 17' fill='none'%3E%3Cpath d='M0.745899 16.5089C0.44784 16.2167 0.420743 15.7595 0.66461 15.4373L0.745899 15.345L7.47342 8.75L0.745899 2.15503C0.44784 1.86283 0.420743 1.40558 0.66461 1.08338L0.745899 0.991064C1.04396 0.698865 1.51037 0.672302 1.83904 0.911374L1.93321 0.991064L9.2541 8.16802C9.55216 8.46022 9.57926 8.91746 9.33539 9.23967L9.2541 9.33198L1.93321 16.5089C1.60534 16.8304 1.07376 16.8304 0.745899 16.5089Z' fill='%23333333' style='&%2310; fill: %23011931;&%2310;'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					background-position: center;
					@include transition(all .5s  ease, opacity 1s ease);
				}
				&.active {
					&:after {
						@include transform(rotate(90deg));
					}
					& ~ .submenu {
						@include transform(scaleY(1));
						@include opacity(1);
						visibility: visible;
						margin-top: 16px;
						height: auto;
					}
				}
			}
		}
		.submenu {
			height: 0;
			@include transform(scaleY(0));
			padding-left: 0;
			@include opacity(0);
			visibility: hidden;
			@include transition(all 0.5s  ease, opacity 1s ease);

			li {
				padding: 0 !important;
				margin-bottom: 8px;
			}
			a {
				font-size: 16px !important;
				padding: 0 !important;
			}
		}
	}
	.product-markdown-view {
		#product {
			padding: 0;
		}
	}
}

@media (max-width: 637px) {
	.success,
	#request-on-order-form,
	#reserve_form,
	#finded-low-cost-form,
	.modal_div {
		.info {
			flex-direction: column;
			align-items: flex-start;

			.img_product,
			.name,
			.quantity_cart_add {
				margin-bottom: 8px;
			}
		}
	}
	.success {
		width: 100%;
		margin: 0 auto;
		left: auto;
		padding: 4%;

		&.compare {
			.success_bg {
				padding: 0;
				.info-cart {
					flex-direction: column;

					.go_shop {
						margin-right: 0;
						margin-bottom: 8px;
					}
				}
				.text {
					float: inherit;
					display: inline-block;
				}
				div {
					width: 100%;
				}
			}

		}
		.success_button {
			padding: 6px;
		}
		& > div:first-child {
			margin-top: 0;
		}
		h3 {
			font-size: 18px;
		}
	}
	#finded-low-cost-form {
		padding: 0;

		.modal-dialog {
		    top: 4% !important;
		    margin: 0;
		    padding: 0 4%;
		}
		button,
		.btn {
		    width: 100%;
		}
	}
	.col-md-12 {
		.refine-search {
			ul, ol {
				li {
					width: calc(33.3% - 20px);
				}
			}
		}
	}
	.product-info {
		.tab-content {
			.product-grid {
				&.no-scroll {
					.product-block {
						margin: 4px 2px;
						width: calc(100% / 2 - 7px);
					}
				}
			}
		}
	}
	.recaptcha_container {
		overflow: hidden;
	}
}

@media (max-width: 570px) {
	#powered {
		.inner {
			padding: 0 16px;
		}
		.block_dir {
			padding: 0 16px;
		}

	}
	.fancy_holiday_works {
		width: 94% !important;
		left: 3% !important;

		.fancybox-skin {
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			   .hw_col {
				    font-size: 16px;
				}
			}
			p{
				max-width: 90%;
				margin: 0 auto 20px auto;
			}
		}
	}
	#page {
		#header-layout {
			.header-top1 {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
	}
	#powered {
		& > .container {
			padding: 0;
		}
	}
	#searchtop {
		border: 0;
		.input-group-btn {
			height: 30px;

		}
		.btn-search {
			line-height: 14px;
		}
	}
	.col-md-12 {
		.refine-search {
			ul, ol {
				li {
					width: calc(50% - 20px);
				}
			}
		}
	}
	.product-info {
		.tab-content {
			.desc-header-wrapper {
				.desc-header {
					display: block;
				}
			}
			.scroll-button {
				margin-bottom: 15px;
				margin-left: 15px;
			}
		}
	}

}
@media (max-width: 538px) {
	.page-product-special,
	.page-product-search {
		#products {
			.product-block {
				width: 100%;
			}
		}
	}
	body[class*="product-special"] #content {
		.limit.pull-right {
			display: inline-block;
		}
	}
	body {
		&.information-direktoru {
			.buttons {
				&.contactForm_input__paddingLeft {
					#button-send {
						width: 100%;
					}
				}
			}
		}
	}
}
@media (max-width: $screen-xs) {
	.product-filter {
		.filter-right {
			& > div {
				&.limit {
					display: none;
				}
			}
			#filter-mob-btn {
				a {
					width: max-content;
				}
			}
		}
	}
	.product-list {
		.product-col {
			.block-img {
				float: none;
				width: 100%;
			}
		}
		.product-meta {
			padding: 20px;
		}
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.product-info {
		.tab-content {
			.product-grid {
				&.no-scroll {
					.product-block {
						margin: 4px 2px;
						width: calc(100% - 5px);
					}
				}
			}
		}
	}
	.success {
		.next {
			.success_button {
				margin-bottom: 0;
			}
		}
	}
}

@media (max-width: 440px) {
	#products {
		.product-block {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		}
	}
	.owl-carousel-play {
		.owl-carousel {
			&.product-grid {
				.owl-stage-outer {
					overflow: unset;
				}
			}
			&.product-col-cat-carousel {
				.owl-stage {
					display: flex;

					.owl-item {
						position: unset;
						float: unset;
						width: max-content !important;
						white-space: nowrap;

						&:last-child {
							margin-right: 0 !important;
						}
					}
				}
			}

		}
	}
	#cart,
	.product-compare-header {
		margin-left: 15px;
	}
	#cart {
		.icon-cart {
			padding: 0;
		}
	}
	#header-layout {
		.header-menu {
			.logo-container {
				svg {
					margin: auto 0;
					display: block;
				}
			}
		}
	}
	.gift-certs {
		background-position-y: bottom;
		padding-bottom: 200px;
	}
}
@media (max-width: 360px) {
	.product-info {
		.product-price-info {
			.ul_info {
				.osn_har {
					li {
						.text {
							min-width: unset;
							max-width: 120px;
							width: 100%;
							vertical-align: top;
						}
					}
				}
			}
		}
		.product-meta {
			.credit_info {
				span {
					right: 18px;
				}
				.tooltip_cust {
					width: 270px;
					right: -46px;
				}
			}
		}
	}

	body.checkout-buy .checkout_cart .confirm_block .checkbox_2 {
		margin-bottom: 16px;
	}
}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: calc(100% - 12px);
		}
	}
	.product-markdown-view {
		.cheaper-item {
			min-width: auto;
		}
	}
}
