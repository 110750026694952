html {
	overflow-x: hidden;
}
body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="page-"] {
		.row {
			margin-right: 0;
			margin-left: 0;
		}
		#content {
			img {
				max-width: 100%;
			}
		}
		section,
		#sidebar-main {
			&[class*="col-"] {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] {
		#content {
			h1, h2 {
				font-size: $font-size-base + 5;
			}
		}
	}

	&[class*="page-product"] {
		#content {
			overflow: visible;
			padding: 0;
			.owl-carousel .owl-wrapper-outer {
				overflow: visible;
			}
			.owl-carousel {
				margin-bottom: 35px;
			}
		}
	}
	&[class*="page-information"] {
		#content {
			max-width: 1024px;
			margin: 0 auto;
			float: unset;
		}
	}
}
#header-layout {
	padding-top: 24px;
}
#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;

	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}
}


.header-bottom {
	& > .container {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 24px;
		height: 48px;

		&:before, &:after {
			content: none;
		}
	}
	#pav-megamenu {
		width: 100%;
	}
	& > .holiday_works {
		@media (min-width: 992px) {
			display: none;
		}
	}
	.worktime_phones {
		display: flex;
		width: max-content;
		justify-content: flex-end;

		#topcontacts {
			display: flex;
		}
		.topcontacts {
			align-self: center;
			align-items: center;
			display: flex;
			position: relative;
			font-size: 13px;

			&.worktime,
			&.phone {
				.media-title {
					display: flex;
					&  > svg {
						padding: 2px;
						@include size(40px,40px);
					}
					a > svg {
						padding: 2px;
						@include size(40px,40px);
					}
				}
				.media-body {
					display: flex;
					max-width: initial;

					span {
						white-space: nowrap;
						display: inline-block;
						&.line {
							margin: 0 5px;
						}
						a {
							color: $txt_black;
						}
					}
				}
			}
			&.phone {
				order: 1;

				.media-body {
					font-size: 18px;
					font-weight: 600;
					line-height: 24px;
					margin-right: 16px;
				}
			}
			&.worktime {
				order: 2;

				.media-body {
					span {
						font-size: 16px;
						font-weight: 300;
						line-height: 22px;
					}
				}
			}
		}

	}
	.main-menu {
		flex: 1 0 auto;
		align-self: center;
	}
	.nav-search {
		flex: 0 1 200px;
		align-self: center;
	}
}
.logo-container {
	width: 100%;
	max-width: 158px;
	margin-right: 40px;

	.logo-store {
		padding: 0;
		text-align: center;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.topcontacts {
		&.phone {
			.media-body {
				font-size: 18px;
				line-height: 22px;
				font-weight: 600;
				letter-spacing: 0.02em;
			}
		}
		&.worktime {
			.media-body {
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;
				letter-spacing: 0.01em;
			}
		}
	}
}
@media (min-width: 992px) {
	#cart .wrap-cart {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.icon-cart {
			width: 100%;
		}
	}
}
header {
	background:$wh;
}
.error {
	color: $tc3;
}
.header-menu {
	position: relative;
	padding-bottom: 32px;

	.logo-container {
		display: none;
	}
	& > .container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		&:before, &:after {
			content: none;
		}
		& > div {
			height: 48px;
		}

		#pav-mainnav,
		.pav-megamenu,
		#menu {
			height: 100%;
		}
	}
	#menu {
		.pc_menu {
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;
			background: $wh;
			overflow: hidden;
			z-index: -1;
			visibility: hidden;
			margin-top: -32px;
			@include opacity(0);
			@include transition(visibility 0s, opacity 0.5s linear);
			@include box-shadow(0 10px 10px $lg);

			& > .container {
					padding-top: 48px;
					padding-bottom: 72px;
				.nav {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					width: 100%;

					&:before,
					&:after {
						content: unset;
					}
				}
			}
		}
		.button_menu_cat:hover,
		.button_menu_cat:focus,
		.button_menu_cat:active {
			.pc_menu{
				@include opacity(1);
				visibility: visible;
				z-index: 99;
			}
		}
	}
	.headercart {
		display: flex;
	}
	.button_menu_cat {
		a,
		.pc_menu {
			&:hover {
				&~.backdrop {
					@include opacity(1);
					visibility: visible;
					display: block;
					z-index: 10;
				}
			}
		}
	}
}
.lvl1 {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
	width: calc(100% / 4 - 16px);
	margin-bottom: 16px;

	&:last-child {
		margin-right: 0;
		margin-bottom: 0;


	}
	&:last-child,
	&:nth-last-child(2) {
		.img {
			img {
				max-width: 100%;
				margin-left: 0;
				margin-top: -10px;
				margin-bottom: 0;
			}
		}
	}
	.img {
		max-height: 172px;
		overflow: hidden;
		margin-bottom: 16px;
		position: relative;

		a {
			display: block;
			height: 100%;
		}
		img {
			margin-top: -200px;
			max-width: 650px;
			height: auto;
			margin-left: -110px;
			margin-bottom: 50px;
		}
	}
	span {
		margin-left: 0;
	}
	.title {
		margin-bottom: 16px;
		display: block;
	}
	.child-title {
		font-weight: 400;
		margin-bottom: 8px;
		line-height: 20px;
		display: block;
	}
	.block-name {
		line-height: 20px;
		letter-spacing: 0.01em;

		a {
			color: $txt_black;
		}
		.name {
			max-width: 175px;
		}
	}
}

.shopping-cart-table {
	background: $white;
	tbody {
		.column-image {
			position: relative;
			img {
				border: none;
			}
			.product-label {
				top: 5px;
				left: 5px;
				padding: 0 5px;
				background: $tc;
				span {
					color: $wh;
				}
			}
		}
	}
	& > thead:first-child > tr:first-child > td {
		border-top: 1px solid $lbc;
	}
	&.table {
		tr td {
			border: 1px $lbc solid;
		}
	}
	.btn {
		font-size: 23px;
		padding: 0 7px;
		i {
			vertical-align: 2px;
		}
	}
}

.tp-bullets .counter {
	display: none !important;
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	a {
		vertical-align: middle;

		svg {
			path {
				fill: $tc;
			}
		}
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		background-size: contain;
		background-position: left center;
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
		max-width: 275px;
		li {
			margin-bottom: 8px;
			a {
				color: $txt_black;
				font-style: normal;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $tc2;
				}
			}
			&.ft-addr, &.ft-wt {
				span {
					white-space: nowrap;
				}
			}
			&.ft-addr {
				display: flex;
				flex-direction: column;

				.metro {
					white-space: nowrap;
				}
			}
			&.ft-phone {
				span{
					display: inline-block;
					font-weight: 600;
					font-size: 20px;
				}
			}
		}
	}
	.block_dir {
		li {
			margin-bottom: 16px;
		}

	}
	.logo-store {
		padding: 0 0 32px 0;

		svg {
			max-width: 409px;
			height: 50px;

			path {
				fill: $tc;
			}
		}
	}
}
s
.footer-top {
	background: transparent;
	margin-bottom: -97px;
}
footer {
	padding-top: 48px;
	margin-top: 104px;

	.ft-topline {
		position: absolute;
		top: -7%;
		z-index: -1;

		path {
			fill: $wh;
		}
	}
	.box-heading {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 24px;
	}
	.powered_block {
		.box-heading {
			margin: 0;
		}
	}
	.link-direktor {
		width: max-content;
		padding: 8px 16px;
	}
}
.footer-center {
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
	.footer-name {
		text-align: center;
		font-size: 12px;
	}
	.main-footer {
		background: $wh;
		display: flex;
		justify-content: space-between;
	}
	.box {
		width: 25%;
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		@include element-absolute($white);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $wh;
	padding-top: 32px;
	padding-bottom: 48px;

	.copyright {
		padding: 5px 0 0 15px;
		color: $white;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.oferta p {
		font-size: 12px;
		color: $wh;
	}
	.payment {
		width: 100%;
		a {
			max-width: 600px;
			display: block;
			margin-top: 14px;
		}
	}
}
.for-send-error-press {
	margin: 15px 0;
	font-style: italic;
}

/* category description */
.bottom_category_desc {
	margin-bottom: 30px;
	background: $wh;
	padding: 20px;
	* {
		font-size: 14px;
		line-height: 20px;
	}
	p {
		padding-bottom: 10px;
		padding-top: 0;
	}
	ul {
		font-size: 16px;
		font-style: normal;
		line-height: normal;
		padding-left: 15px;
		margin: 15px 0;
		color: $bk;
		li {
			list-style: disc;
			font-weight: 400;
		}
	}
	h2, h3 {
		font-size: 24px;
	}
}

#callback_form .callback_button,
.button_lg,
.button_lg_inv,
.red_button,
.button_red_inv {
	@include transition(all 0.2s  ease-in-out);
}
#callback_form .callback_button,
.sphinx-viewall #view-all,
.red_button,
.success_button,
.btn-primary {
	& {
		width: max-content;
		padding: 16px 24px;
		align-items: center;
		display: flex;
		justify-content: center;
		background: $tc;
		color: $wh;
		cursor: pointer;
		font-weight: 500;
		border: none;
		@include box-shadow(none);
		@include rounded-corners(4px);
		svg {
			path {
				fill: $wh;
			}
		}
	}
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: $wh;
		background: $tc2;
		text-decoration: none;
	}
}
.btn-transparent {
	background: transparent;
	border: 2px solid $tc;
	color: $tc;

	span {
		color: $tc;
	}
	svg {
		path {
			fill: $tc;
			stroke: $tc;
		}
	}

	&:hover {
		background: $tc;
		border: 2px solid $tc;
		color: $wh;

		span {
			color: $wh;
		}
		svg {
			path {
				fill: $wh;
				stroke: $wh;
			}
		}
	}
}
.product-info {
	#product {
		#button-cart{
			padding: 0;
			span {
				width: 100%;
			}
		}
	}
}
.button_lg {
	padding: 11px 16px;
	align-items: center;
	display: flex;
	justify-content: center;
	background: $wh;
	color: $lg;
	@include rounded-corners(4px);
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
}
.button_lg_inv {
	border: solid 1px $mg;
	color: $mg;
}
.button_lg:hover,
.button_lg:focus,
.button_lg:active,
.button_lg.active {
	background: $tc;
	color: $wh;
	text-decoration: none;
}
.button_red_inv {
	padding: 11px 0;
	align-items: center;
	display: flex;
	justify-content: center;
	@include size(160px,100%);
	color: $tc;
	border: solid 1px $tc;
	@include rounded-corners(4px);
	cursor: pointer;
	font-weight: 600;
	background: $wh;

	svg {
		path{
			stroke: $tc;
			@include transition(all 0.2s  ease-in-out);
		}
	}
	&:hover,
	&:focus,
	&:active,
	&.active {
	    border-color: $tc2;
	    color: $wh;
	    text-decoration: none;
		background: $tc;

	    svg {
			path {
				fill: $wh;
			}
		}
	}
}

.backdrop{
	position: fixed;
	width: 100%;
	background: rgba(104, 104, 104, 0.7);
	left: 0;
	top: 144px;
	bottom: 0;
	overflow: hidden;
	z-index: -1;
	@include opacity(0);
	display: none;
	@include transition(all 0.2s  ease-in-out);
}




button,
input {
	&#callback_form .callback_button,
	&.sphinx-viewall #view-all,
	&.red_button,
	&.success_button,
	&.button_lg,
	&.btn-primary {
		display: inline-block;
		min-height: 44px;
		padding: 0 24px;
	}
}