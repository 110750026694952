.owl-carousel {
	display: block;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}


.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		li {
			padding: 10px 0;
			margin: 0 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				background: no-repeat transparent top center;
				background-size: 90%;
				padding-top: 150px;
				box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
				&:hover {
					text-decoration: none;
					span {
						color: $wh;
						background: rgba(30, 72, 123, 0.7);
					}
				}
				span {
					width: 100%;
					padding: 10px 5px;
					text-align: center;
					font-weight: 600;
					color: $tc;
					background: rgba(255,255,255,.7);
					line-height: 17px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include transition (all 200ms ease-in-out);
					figure {
						text-align: center;
					}
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	margin: 0 0 32px 0;
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.pull-left + .pull-left {
		margin-left: 16px;
	}
	.sort {
		display: flex;
		justify-content: flex-start;
		background: $wh;

		.item {
			margin-right: 16px;
			color: $lg;
			cursor: pointer;
			padding: 15px 0;

			&:nth-child(2) {
				padding-left: 24px;
			}
			&:last-child {
				padding-right: 24px;
				margin-right: 0;
			}
			&.active {
				color: $txt_black;
				font-weight: 600;
			}
		}
		#input-sort {
			margin: 0;
		}
	}
}
.product_applying {
	margin-top: 24px;
	margin-bottom: 16px;
	padding-bottom: 8px;
	border-bottom: 1px solid $lg_gray;

	.items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.item {
			margin-right: 8px;
			margin-bottom: 8px;

			img {
				@include size(48px,48px);
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 24px;
	}

}
.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
	}
	.product-block {
		display: flex;
		border: 1px solid $lg_gray2;
		margin: 10px 0;
		@include rounded-corners(0);
		.name {
			font-weight: 600;
			font-size: 18px;
			height: auto !important;
			a {
				span {
					display: inline-block;
				}
			}
		}
		.price {
			margin-bottom: 6px;
			text-align: left;
			.price-new {
				font-size: 26px;
			}
		}
		.block-img {
			width: 30%;
		}
		.bottom {
			display: flex;
			padding: 0 20px;
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
			.quickview {
				a {
					left: -1px;
					position: relative;
				}
			}
		}
	}
}

#products {
	.products-block {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
	}
	.product-block {
		width: calc(100% / 4 - 13px);
		margin-bottom: 16px;
	}
}
.product-block {
	.product_applying {
		margin-top: 8px;
		padding-bottom: 0;
		border-bottom: 0;


		.items {
			align-items: flex-start;

			.item {
				margin-right: 4px;
				margin-bottom: 4px;

				img {
					@include size(32px,32px);
				}
			}
		}
	}
}
#sidebar-left {
	padding: 0;
	.box {
		background: $wh;
	}
	.category.box {
		margin-bottom: 0;

		&.parent-box {
			padding-bottom: 0;
		}
		.cat-list {
			padding: 16px 16px 0 16px;

			li {
				padding: 0;
				margin-bottom: 8px;

				&:last-child {
					margin-bottom: 0;
					padding-bottom: 32px;
					border-bottom: 1px solid $lg_gray2;
				}
			}
		}
	}
	.mfilter-box.box {
		padding: 16px;
	}
}
#column-left,
#column-right {
	display: inline-block;
	width: 100%;
}
#sidebar-left + #sidebar-main {
	padding: 0 0 0 24px;
}
.product-grid,
.products-owl-carousel {
	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.product-block {
		display: inline-block;
		margin-right: 16px;
		padding: 41px 0 24px 0;
		@include transition(border 0.2s ease-in-out);

		&:nth-child(4n+4) {
			margin-right: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		.product-meta {
			padding: 0 24px;

			.top {
				margin-top: 7.5px;
			}
		}
		.bottom {
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			background-color: $white;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			@include transition (all 400ms ease 0s);
			margin-top: 8px;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}

	.product-info-bg{
		display: flex;
		justify-content: space-between;
		padding-bottom: 40px
	}
	.product_stik {
		max-width: 960px;
		width: 100%;
	}
	.image-container {
		width: 90%;
		max-width: 700px;
		margin-right: 40px;

		.image_block,
		.image-thumb {
			background: $wh;
			position: relative;
		}
		.thumbnail {
			img {
				max-height: 526px;
			}
		}
		.image-gallery {
			padding-top: 24px;
			background: $lg_bg;
		}
		.image-additional {
			overflow: hidden;

			.owl-carousel {
				margin-bottom: 0 !important;
			}
			.item {
				text-align: center;
				position: relative;

				img,
				a {
					@include transition (all 200ms ease-in-out);
				}
				a {
					display: block;
					@include size(80px,80px);
				}
				a.active,
				a:hover {
					img {
						filter: brightness(0.7);
					}
				}
			}
		}
	}
	.sku_rating_compare_credit {
		margin-bottom: 24px;
	}
	.sku_rating {
		display: flex;
		font-size: 12px;
		align-items: center;

		& > div {
			margin-right: 16px;
			margin-bottom: 8px;

			&:last-child {
				margin-right: 0;
			}

			svg {
				margin-right: 4px;
			}
		}
	}
	.sku_block {
		color: $lg;
	}
	.prod_rating {
		& > .rating {
			display: flex;
			font-size: 12px;
			line-height: 14px;

			.rat_count {
				font-weight: 600;
			}
			.rev_count{
				margin-left: 5px;
				color: $lg;
			}
			svg {
				path {
					fill: $bk;
				}
			}
			&.active {
				svg {
					path {
						fill: $tc;
					}
				}
				.rat_count {
					color: $tc;
				}
			}
		}
	}
	.compare,
	.credit-container {
		svg {
			@include size(15px,15px);

			path {
				fill: $tc;
			}
		}
	}
	.product-price-info {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;

		.ul_info {
			width: 100%;
			padding-right: 32px;

			.osn_har {
				li {
					margin-bottom: 8px;
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;

					&:last-child {
						margin-bottom: 0;
					}
					& > span {
						display: inline-block;
						vertical-align: bottom;
					}
					.text {
						min-width: 170px;
						margin-right: 8px;
					}
					.value {
						font-weight: 600;
						white-space: nowrap;
					}
				}
				img {
					margin-left: 5px;
					margin-top: -10px;
				}
			}
			.faq {
				ul {
					padding-left: 0;
					margin-bottom: 0;

					li {
						display: flex;
						align-items: flex-start;
						list-style: none;
						margin-bottom: 16px;

						&:before {
							content: '';
							display: inline-block;
							@include size(24px,24px);
							min-width: 24px;
							margin-right: 10px;
							background-image: url("data:image/svg+xml,%3Csvg%20fill%3D%22none%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url(%23clip0_3509_128120)%22%3E%3Cg%20opacity%3D%220.2%22%3E%3Cpath%20d%3D%22M-4.89661%201.79541C0.557449%201.79541%200.557449%206.05497%206.07802%206.05497C11.5321%206.05497%2011.5321%201.79541%2017.0526%201.79541C22.5732%201.79541%2022.5732%206.05497%2028.0273%206.05497C33.4813%206.05497%2033.4813%201.79541%2039.0019%201.79541%22%20stroke%3D%22%230055FF%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%20opacity%3D%220.5%22%3E%3Cpath%20d%3D%22M-4.89661%209.08447C0.557449%209.08447%200.557449%2013.344%206.07802%2013.344C11.5321%2013.344%2011.5321%209.08447%2017.0526%209.08447C22.5732%209.08447%2022.5732%2013.344%2028.0273%2013.344C33.4813%2013.344%2033.5478%209.08447%2039.0019%209.08447%22%20stroke%3D%22%230055FF%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cpath%20d%3D%22M-4.89661%2016.3716C0.557449%2016.3716%200.557449%2020.6311%206.07802%2020.6311C11.5321%2020.6311%2011.5321%2016.3716%2017.0526%2016.3716C22.5732%2016.3716%2022.5732%2020.6311%2028.0273%2020.6311C33.4813%2020.6311%2033.5478%2016.3716%2039.0019%2016.3716%22%20stroke%3D%22%230055FF%22%20stroke-miterlimit%3D%2210%22%20stroke-width%3D%222%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_3509_128120%22%3E%3Crect%20fill%3D%22white%22%20height%3D%2222.7368%22%20transform%3D%22translate(0%200.631348)%22%20width%3D%2224%22%3E%3C%2Frect%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
    #product {
    	position: relative;
    	padding: 32px;
		background: $wh;
		@include rounded-corners(2px);
    	max-width: 321px;

    	.price.detail {
    		display: flex;
    		align-items: first baseline;
    		justify-content: flex-start;

    		.price-new {
    			margin: 0;
    			display: flex;
    			align-items: flex-end;
    			font-weight: 500;
				line-height: 30px;
				width: max-content;

    			span {
					font-family: $f;
    				font-size: 16px;
					line-height: 18px;
					font-weight: 600;
					margin: 0 0 0 8px;
    			}
    		}
    		.price-old {
    			position: relative;
    			font-weight: 600;
    			font-family: $f;
    			margin: 0 0 2px 16px;
    			color: $lg;
				letter-spacing: 0.01em;
				line-height: 20px;

    			&:before {
    			  position: absolute;
    			  content: "";
    			  left: 0;
    			  top: 35%;
    			  right: 0;
    			  border-top:solid 2px $bk;
    			  border-color: inherit;
    			  @include transform(rotate(-8deg));
    			}
    		}
    	}

    }
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 0;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity {
		padding-bottom: 15px;
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 35px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
			&:hover, &:focus {
				color: $theme-color;
			}
		}
		.quantity-number {
			input {
				@include size(40px, 35px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				@box-shadow(none);
				padding: 0;
				border: none;
				color: $black;
				font-size: 16px;
			}
		}
		.minimum {
			line-height: 35px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			a.btn-sm {
				padding: 0 19px;
			}
			&.btn-primary {
				margin-left: 16px;
				font-size: 20px;
				line-height: 33px;
				padding: 0 10px;
			}
		}
		.cart {
			#button-cart {
				width: 246px;
				height: 44px;
			}
		}
		.btn-compare {
			color: $bk;
			font-size: 24px;
			padding: 2px 18px;
			height: 41px;
			&:hover {
				color: $tc;
			}
		}
		.other-info {
			margin-bottom: 16px;
		}
		.credit_info {
			position: relative;
			cursor: pointer;
			.tooltip_cust {
				position:absolute;
				top: 38px;
				width: 320px;
				background: $wh;
				@include box-shadow(1px 1px 10px $bk);
				padding: 20px;
				font-size: 16px;
				z-index: -1;
				@include opacity(0);
				display: none;
				right: -85px;
				@include transition (all 200ms ease-in-out);
			}
			&:hover, &:focus, &:active {
				.tooltip_cust {
					z-index: 1;
					@include opacity(1);
					display: block;
				}
			}
			& span {
				position: absolute;
				right: 56px;
				top: -25px;
				@include size(65px,25px);
				overflow: hidden;
				@include opacity(1);
			}
			& span:before {
				content: '';
				position: absolute;
				left: 15px;
				top: 12px;
				@include size(25px,25px);
				@include box-shadow(1px 1px 10px $bk);
				background: $wh;
				@include transform(rotate(45deg));
			}
		}
		.credit_product {
			display: flex;
			align-items: center;
			.credit_info {
				display: flex;
				margin-left: 4px;
			}
			&:first-child {
				margin-bottom:8px;
			}
			a {
				&:hover,
				&:focus,
				&:active {
					color: $tc2;
				}
			}
		}
		.other-dost {
			display: none;

			.other-title {
				font-weight: 600;
				letter-spacing: 0.01em;
			}
			b {
				font-weight: normal;
				text-transform: capitalize;
			}
			& > div {
				&:first-child {
					margin-bottom: 8px;
				}
				span {
					white-space: nowrap;
				}
			}
			.other-dost1,
			.other-dost2 {
				display: flex;
				flex-direction: column;
			}
		}
		.reserv_block {
			border-top: solid 1px $lg2;
			padding-top: 8px;
			margin-top: 16px;
			a {
				color: $tc;
				font-weight: 600;
				&:hover,
				&:focus,
				&:active {
					color: $tc2;
				}
			}
		}

	}
	.advantages_block {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 10px;

		.advantage_item {
			width: 50%;
			min-width: 262px;
			display: flex;
			align-items: center;

			div {
				@include size(80px, 80px);
				margin: 10px;
			}
			span {
				width: calc(100% - 100px);
				margin: 10px;
			}
		}
		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}
.product-markdown-view {
	#product {
		padding: 0;
		background: transparent;

		.price {
			&.detail {
				.price-new, .price-old {
					line-height: 1;
				}
			}
		}

	}
	.quantity {
		padding: 0;
	}
	.product-meta {
		.other-info, .credit_product {
			margin: 0 !important;
		}
		.cart {
			#button-cart {
				width: 100%;
			}
		}
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid $ddg;
		color: $txt_black;
		min-width: 321px;

		&:not(.general) {
			border: 1px solid $lg;

			.price {
				.price-new {
					font-size: 24px;
				}
			}
		}
	}
	.cheaper-products {
		margin-top: 20px;
	}
	.cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info, .other-info, .product-meta {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.other-info {
		gap: 8px;
	}
	.cheaper-products, .cheaper-item {
		&__title {
			font-size: 18px;
			font-weight: 600;
		}
		&__reason {
			color: $lg;
		}
		&__status {
			color: $lg;
			font-weight: 600;
		}
	}
}


/*
* ALLPRODUCTS PAGE
*/
.all_cat_block {
	display: flex;
	flex-wrap: wrap;
	a {
		@include transition(0.2s all);
	}
	.cat {
		width: calc(100% / 3 - 11px);
		margin-right: 16px;
		margin-bottom: 64px;

		.img {
			height: 288px;
			overflow: hidden;
			position: relative;

			img {
				position: absolute;
				left: 0;
				bottom: 0;
				max-width: 810px !important;
				margin-left: -130px;
			}
		}
		.title {
			margin-top: 16px;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			color: $txt_black;
		}
		&:nth-child(3n + 3) {
			margin-right: 0;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	padding-bottom: 80px;

	h1 {
		margin: 0;
		padding: 0;
		line-height: 40px;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha,
#input-payment-captcha {
	margin-bottom: 10px;
}

