button {
	&:active, &:focus {
		outline: none !important;
	}
}

#page {
	min-height: 100vh;
}
.recaptcha_container {
	.col-sm-2 {
		padding-right: 0;
		width: 1px;
	}
}
.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}
.product-compare{
	background: $wh;
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#button-cart {
	width: 122px;
}
.success.product-info .product_list_item .image {
	width: 80px;
}

#tab-description {
	img {
		height: auto;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}
.pav-container {
	.pav-inner {
		.row-inner {
			& > div {
				padding: 0;
			}
		}
	}
}
.advantages img, .advantages .col-sm-9 {
	height: auto !important;
}
#is_ip .radio_custom .input-control {
	margin-right: 15px;
}
.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(0);
		background: $tc;
		@include transition(0.2s all);
		padding: 10px 15px;
		text-transform: uppercase;
		font-family: $font-family-base;
		&:hover {
			background: $black;
		}
	}
	.reserve_button {
		padding: 0px 20px;
	}
}

.contact-icon {
	display: inline-block;
	@include size(50px,50px);
	@include rounded-corners(50%);
	background: $tc;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		@include size(50px,50px);
		text-align: center;
		margin-top: -1px;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: 1px solid $wh;
			padding: 0 8px;
			height: 36px;
			&:focus, &:active {
				outline: none !important;
			}
			i {
				font-size: 23px;
				vertical-align: -2px;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		@include rounded-corners(0);
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px $gray2 solid;
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px 20px 0;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					@include size(100%,auto);
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 600;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: $mg;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 767px) {
			width: 260px;
			right: -15px;
			left: auto;
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

#checkout-form #shipping_methods .radio,
#checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 400;
		color: $lg;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			@include opacity(0);
		}
		&__indicator {
			@include rounded-corners(50%);
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			@include user-select(none);
			@include size(15px, 15px);
			text-align: center;
			background-color: $tc;
		}
		&.radio input ~ .input-control__indicator:after {
			@include rounded-corners(50%);
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			@include transform(scale(0.8));
			@include transform-origin(center center);
			@include size(100%,100%);
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
			border: 3px $white solid;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		padding-left: 30px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 400;
		color: $bk;
		margin: 16px 0;
		text-align: left;
		display: flex;
		align-items: center;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			@include opacity(0);
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(24px, 24px);
			text-align: center;
			background: none;
			border: solid 2px $lg2;
			@include rounded-corners(4px);
			@include transition(200ms all ease-in-out);
		}
		&.checkbox input ~ .input-control__indicator:after, &.checkbox.active .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left:0;
			@include opacity(0);
			@include size(100%,100%);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M10.6663 1.5L4.24967 7.91667L1.33301 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			@include transition(200ms all ease-in-out);
		}
		&.checkbox input:checked ~ .input-control__indicator:after, &.checkbox.active .input-control__indicator:after {
			@include opacity(1);
		}
		&.checkbox input:checked ~ .input-control__indicator {
			border-color: $tc;
			background-color: $tc;
		}
		&:hover {
			.input-control__indicator {
				border-color: $mg;
			}
		}
	}
}

#button-order {
	margin-top: 15px;
}
.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		button {
			float: left;
			margin-right: 10px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	@include size(40px,40px);
	background-color: $tc;
	text-indent: -9999px;
	z-index: 999;
	border: 1px $white solid;
	@include transition(200ms all ease-in-out);
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -8px;
		margin-top: -12px;
		@include size(0,0);
		border: 8px solid transparent;
		border-bottom-color: $wh;
	}
	&:hover {
		background-color: $bk;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
		background: transparent;

		&:hover,
		&:focus,
		&:active,
		&.active {
			color: $tc;
		}
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid {
		.product-block {
			margin: 0;
			width: auto;
		}
	}
	.owl-carousel .owl-item {
		@include transition(all 0.3s ease);
	}
}

h1 {
	padding: 0 0 40px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px 0;
	margin-bottom: 15px;
	overflow-x: auto;
	table {
		margin-bottom: 0;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
	}
	.button_lg_inv, .red_button {
	    width: 160px;
	}
	.red_button {
	    margin-bottom: 8px;
	}

}
.page-product-special, .page-product-search {
	#filter-mob-btn {
		display: none !important;
	}
	#content{
		padding-bottom: 30px !important;
	}
	#products .product-block {
		width: calc(100% / 5 - 16px);
	}
}
.owl-prev, .owl-next {
	position: absolute;
	top: -33px;
	@include size(25px,25px);
	color: #fff;
	@include transition(0.2s ease all);
	background: $tc center no-repeat;
	font-family: $fa;
	font-size: 22px;
	line-height: 1;
	padding: 1px;
	&:hover {
		background-color: $mg;
	}
}

.image_block {
	.mob-owl-nav {
		display: none;
	}
	.thumbnail {
		padding: 0;
	}
	#image-additional {

		.owl-prev,
		.owl-next {
			top: 17px;
		}
		.owl-next {
			right: 0;
		}
		.owl-prev {
			left: 0;
			right: auto;
		}
	}
}


.owl-prev {
	right: 31px;
	&:before {
		content: '\f111';
	}
}

.owl-next {
	right: 3px;
	&:before {
		content: '\f112';
	}
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 0 0 21px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include transition(all 0.2s ease-in-out);
			}
			&.mfilter-collapsed {
				.mfilter-heading-content {
					padding: 0;	
				}
			}
			.mfilter-head-icon {
				@include transition(none);
				@include notransform();
				font-family: $fa;
				background: none;
				font-style: normal;
				padding: 0;
				margin: 0;
				display: flex;
				align-items: center;
				@include size(12px,auto);
				&:before {
					content: "";
					display: flex;
					@include size(12px,2px);
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='2' viewBox='0 0 12 2' fill='none'%3E%3Cpath d='M11.1 1.8H0.9C0.4 1.8 0 1.4 0 0.9C0 0.4 0.4 0 0.9 0H11.1C11.6 0 12 0.4 12 0.9C12 1.4 11.6 1.8 11.1 1.8Z' fill='%23333333'/%3E%3C/svg%3E");
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
			}
			&.mfilter-collapsed {
				.mfilter-head-icon {
					&:before {
						content: "";
						display: flex;
						@include size(12px,12px);
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cpath d='M11.1 5.1H6.9V0.9C6.9 0.4 6.5 0 6 0C5.5 0 5.1 0.4 5.1 0.9V5.1H0.9C0.4 5.1 0 5.5 0 6C0 6.5 0.4 6.9 0.9 6.9H5.1V11.1C5.1 11.6 5.5 12 6 12C6.5 12 6.9 11.6 6.9 11.1V6.9H11.1C11.6 6.9 12 6.5 12 6C12 5.5 11.6 5.1 11.1 5.1Z' fill='%23333333'/%3E%3C/svg%3E");
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.mfilter-tree,
		.mfilter-categories {
			border-top: 0;
			border-bottom: 1px solid $lg_gray2;
			margin-bottom: 16px;

			.mfilter-heading {
				display: none;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 0;
			padding:0;
			.checkbox_custom  {
				margin-bottom: 8px;
			}
			label {
				font-weight: 400;
				line-height: initial;
				padding-top: 0;
				padding-bottom: 0;
			}
			.mfilter-col-count {
				width: max-content;
			}
			.mfilter-counter {
				background: none;
				color: $mg;
				font-weight: 600;
				font-size: 16px;
				&:after {
					content: none;
				}
				&.mfilter-close {
					background: url(/catalog/view/theme/default/stylesheet/mf/images/icons.png?v7) no-repeat 0 0;
				}
			}
			input[type="text"] {
				@include rounded-corners(0);
				height: 45px;
			}
			.form-control[disabled],
			.form-control[readonly],
			fieldset[disabled] .form-control {
				background: none;
			}
			.mfilter-slider-slider,
			#mfilter-price-slider {
				margin: 15px 0px 0 10px !important;
				height: 4px !important;
				background: $lg2 !important;

				.ui-slider-range {
					background: $tc !important;
					z-index: 0;
					@include opacity(0.3)
				}
				.ui-slider-handle {
					background: $tc !important;
					@include size(18px!important,18px!important);
					border: solid 2px $tc !important;
					@include rounded-corners(50%);
					top:-7px;
					z-index: 0;
				}
			}
			.mfilter-slider-slider,
			#mfilter-price-slider {
				height: 4px !important;
			}
			.mfilter-category-tree {
				.mfilter-to-parent {

					a {
						font-weight: 500;
					}
					&:before {
						content: '';
						display: block;
						position: absolute;
						@include size(24px,24px);
						background: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2010%2017%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.745899%2016.5089C0.44784%2016.2167%200.420743%2015.7595%200.66461%2015.4373L0.745899%2015.345L7.47342%208.75L0.745899%202.15503C0.44784%201.86283%200.420743%201.40558%200.66461%201.08338L0.745899%200.991064C1.04396%200.698865%201.51037%200.672302%201.83904%200.911374L1.93321%200.991064L9.2541%208.16802C9.55216%208.46022%209.57926%208.91746%209.33539%209.23967L9.2541%209.33198L1.93321%2016.5089C1.60534%2016.8304%201.07376%2016.8304%200.745899%2016.5089Z%22%20fill%3D%22%230055FF%22%2F%3E%3C%2Fsvg%3E");
						background-repeat: no-repeat;
						background-size: 19px 19px;
						background-position: left;
						@include transform(rotate(180deg));
						margin-left: -24px;
					}
				}
				.mfilter-tb,
				.mfilter-tb-as-tr,
				.mfilter-tb-as-td {
					border: none;
				}
				.mfilter-tb-as-tr {
					padding: 0;
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 8px;
					}
					.mfilter-tb-as-td {
						padding: 0;

						a {
							color: $txt_black;
							font-size: 16px;
							line-height: 22px;

							&.active {
								color: $tc;
							}
						}
					}
				}
			}
		}
		.mfilter-filter-item {
			.mfilter-options {
				padding: 0;
				.mfilter-price {
					.mfilter-price-slider {
						padding: 0 !important;
						background: $lg2 !important;
					}
				}
			}
		}
	}
	.mfilter-content > ul > li {
		padding: 16px 0 32px 0;
		border-top: solid 1px $lg_gray2;
	}
	.mfilter-tb-as-tr {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.checkbox {
			margin: 0;
		}
		&:last-child {
			label {
				padding-bottom: 0 !important;
				padding-top: 0;
			}
		}
	}
	.mfilter-filter-item.mfilter-price.mfilter-price {
		padding-top: 0;
		border: none;
	}
	.checkbox_custom .input-control {
		padding-left: 32px;
	}
	.mfilter-button-bottom {
		border: none;
		padding: 0;
		a {
			margin: 0;
		}
	}
	.mfilter-button {
		.mfilter-button-reset,
		.btn-primary {
			line-height: 22px;
			font-size: 14px;
		}
		.mfilter-button-reset {
			margin-bottom: 24px;
			.mfilter-reset-icon {
				display: none;
			}
		}
		.btn-primary {
			height: auto;
			width: 100%;

			#total-count-product {
				margin-left: 10px;
			}
		}
	}
	.mfilter-price-inputs, .mfilter-slider-inputs {
		text-indent: -9999px;
		  line-height: 0;
		  overflow: hidden;
	}
	.mfilter-price-inputs, .mfilter-slider-inputs {
		display: flex;
		justify-content: space-between;
		padding-bottom: 13px;
	}
	.input_sliders {
		max-width: inherit;
		@include size(calc(50% - 4px),38px!important);
		text-align: left;
		border: 1px solid $lg_bg;
		@include box-sizing(border-box);
		@include rounded-corners(4px!important);
		font-size: 16px;
		color: $mg;
		display: flex;
		align-items: center;
		padding: 0 16px;

		#mfilter-opts-price-min,
		#mfilter-opts-price-max {
			font-size: 16px;
			color: $mg;
			@include size(auto,100%);
			max-width: calc(100% - 20px);
			border: none;
			background: none !important;
			padding: 0 0 0 5px;
		}
		span {
			text-indent: initial;
			@include size(20px,100%);
			line-height: 0;
			align-items: center;
			display: flex;
		}
	}
	#mfilter-opts-price-max,
	.mfilter-opts-slider-max {
		float: right;
	}

	.mfilter-price-slider {
		padding-right: 0!important;
		margin-right: 0!important;
		background: none !important
	}
	.mfilter-slider-container {
	}
	.mfilter-opts-container label {
		font-size: 16px;
		line-height: 22px;
		color: $bk;
	}
	.mfilter-option {
		& > div, & > label {
		border: 0;
		}
	}
	.mfilter-button-more {
		border:0;
		color: $tc;
		text-align: left;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		padding: 4px 0;
		text-decoration: none!important;
		.fa-chevron-down {
			&:before {
				top:2px;
				content: '\f112';
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}
.selectric-hidden {
	display: none;
}
.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	@include rounded-corners(4px);
	background: $wh;
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	height: 54px;
	padding: 0 21px 0 16px;
	.label {
		overflow: visible;
		padding: 0;
		color: $txt_black;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		text-transform: none;
		@include size(auto,100%);
		display: flex;
		align-items: center;
		margin-right: 13px;
		@include transition(all 0.2s ease-in-out);
	}
	&:hover, &:active, &:focus {
		.label {
			color: $tc2;
		}
	}
	.button {
		display: block;
		position: relative;
		right: 0;
		top: 0;
		color: $txt_black;
		text-align: center;
		@include size(32px,32px);
		padding: 5px 12px;
		&:after {
			content: '';
			display: block;
			@include size(12px,21px);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='17' viewBox='0 0 10 17' fill='none'%3E%3Cpath d='M0.745899 16.5089C0.44784 16.2167 0.420743 15.7595 0.66461 15.4373L0.745899 15.345L7.47342 8.75L0.745899 2.15503C0.44784 1.86283 0.420743 1.40558 0.66461 1.08338L0.745899 0.991064C1.04396 0.698865 1.51037 0.672302 1.83904 0.911374L1.93321 0.991064L9.2541 8.16802C9.55216 8.46022 9.57926 8.91746 9.33539 9.23967L9.2541 9.33198L1.93321 16.5089C1.60534 16.8304 1.07376 16.8304 0.745899 16.5089Z' fill='%23333333' style='&%2310; fill: %23011931;&%2310;'/%3E%3C/svg%3E");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			@include transition(all 0.2s  ease-in-out); 
		}
	}
}

.selectric-focus .selectric {
	border-color: $lg2;
}

.selectric-hover .selectric {
	.button {
		color: $lg2;
		&:after {
			border-top-color: $lg2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(90deg));
	}
	.selectric-items {
		height: auto !important;
		display: block;
		@include transform(scale(1));

	}
}
.product-search {
	.selectric-open .selectric-items {
	    width: auto !important;
	    min-width: 320px;
	}
	.selectric .label {
		width: 100%;
	}
	#content .row{
		& > .col-sm-4 {
			margin-bottom: 16px;
			input {
			    height: 44px;
			}
		}
		& > .col-sm-3 {
			margin-bottom: 16px;
			.selectric{
				height: 44px;
			}
		}
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	@include opacity(0.5);
	@include opacity(0.5);
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			box-sizing: border-box;
			@include opacity(0);
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	position: absolute;
	top: 55px;
	right: -11px;
	background: $wh;
	@include box-shadow(0 0 10px $lg);
	transform: scale(0);
	@include transition(all 0.2s  ease-in-out); 
	display: none;

	.selectric-scroll {
		height: 100%;
		overflow: visible;
		position: relative;

		span {
		    position: absolute;
		    right: 0;
		    top: -25px;
			@include size(65px,25px);
		    overflow: hidden;
		    @include opacity(1);

		    &:before {
		        content: '';
		        position: absolute;
		        left: 15px;
		        top: 12px;
				@include size(25px,25px);
		        @include box-shadow(1px 1px 10px $lg);
		        background: $wh;
		        @include transform(rotate(45deg));
		    }
		}
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 16px 24px;
		margin: 0;
		font-size: 16px;
	}
	li {
		display: block;
		color: $lg;
		text-transform: none;
		cursor: pointer;
		margin-bottom: 8px;
		@include transition(all 0.2s  ease-in-out); 
		&:last-child {
			margin-bottom:0;
		}
		&.selected,
		&.highlighted,
		&:hover {
			color: $tc2;
		}
	}
	.disabled {
		@include opacity(0.5);
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		@include user-select(none);
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			@include user-select(none);
			color: #444;
		}
		&.disabled li {
			@include opacity(1);
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 75px;
	.selectric-scroll span{
		top: auto;
		bottom: -25px;
		@include transform(rotate(-180deg));
		right: 20px;
	}
}
.page-home {
	.producttabs,
	.product-cols {
		padding-top: 104px;
	}
}
.widget-products {
	.tcarousel {
		.owl-stage.active {
			.owl-item {
				&:nth-child(5n+5) {
					margin-right: 0 !important;
				}
			}
		}
	}
	.tab-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 56px;
	}
	.v_all {
		color: $bk;
		font-size: 18px;
		font-weight: 600;
		display: flex;
		align-items: center;
		cursor: pointer;
		span {
			padding: 0 7px;
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
		}
		svg {
			padding: 0 7px;
			box-sizing: content-box;
		}
		&:hover,
		&:active,
		&:focus {
		  color: $link-hover-color;
		  svg path{
		    fill: $link-hover-color;
		  }
		}
	}
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-stage,
	.product-block,
	.v_all_close {
		@include transition(0.2s all !important);
	}
	.owl-stage.active {
		width: 100% !important;
	}
	.product-block.active {
		margin-bottom: 16px;
	}
	.v_all_close {
		width: 100%;
		margin: 15px auto 0 auto;
		&.none {
			overflow: hidden;
			height: 0;
			padding: 0;
			border: none;
		}
	}
}
.viewed-container {
	padding-top: 80px;
}
.content_bottom_cat {
	.viewed-container {
		padding-bottom: 104px;
	}
}
.page-information{
	h1 {
		margin-top: 0;
	}
	.main-columns {
		padding-bottom: 104px;
	}
}
.checkout-buy {
	.h1_container {
		padding-bottom: 0;
	}
	h1 {
		margin: 0;
		padding-bottom: 56px;
	}
}
.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		display: flex;
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		display: flex;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.holiday_works {
	text-align: center;
	border: none !important;
	margin: 0 !important;
	display: block;
	color: red !important;
	position: absolute;
	right: 110px;
	bottom: -10px;

	&:hover {
		cursor: pointer;
	}
}

.quick-view {
	#content {
		padding: 0;
	}
}

#form-review-container {
	.recaptcha_container label {
		display: none;
	}
}

.prod_rating {
	.custom-color {
		cursor: pointer;
	}
}

#footer {
	.ft-wt {
		display: flex;
		flex-direction: column;
	}
	.ft-phone {
		span {
			a {
				font-size: 20px;
				line-height: 24px;
				font-weight: 600;
			}
		}
		span:first-child {
			letter-spacing: 0.1px
		}
	}
}
.banner_home {
	margin-top: 40px;
}
.common-home {
	header {
		position: relative;

		.pav-megamenu .navbar-nav > li > a,
		.header-bottom .worktime_phones .topcontacts .media-body {
			a {
				color: $wh;

				&:hover,
				&:focus,
				&:active {
					color: $tc2;

					.caret {
						&:before {
							color: $tc2;
						}
					}
				}
			}
		}
		.holiday_works{
			font-weight: 600;
		}
	}
}
.checkbox_custom {
	.input-control {
		font-size: 16px;
		text-transform: none;
	}
}
.bg_white {
	background: $wh;
}
.bg_light_blue {
	background: $lg_bg;
}


//@media screen and (max-width: 1395px) {
//	#compare-name,
//	#compare-number-name,
//	#cart-number-count,
//	#cart-summa-count {
//		display:none !important;
//	}
//}
//
//@media screen and (max-width: 1200px) {
//	#cart-summa-count {display:none !important;}
//}

.success,
#request-on-order-form,
#reserve_form,
#finded-low-cost-form,
.modal_div {
	top:0;
	padding: 10px 24px;

	#products {
		.product-block {
			margin-bottom: 8px;
			width: 99%;
			background: $lg_bg;
			@include rounded-corners(0);

			.name,
			.price-new {
				margin-bottom: 4px;
			}
		}
	}
	img {
		&.close {
    	height: 8px;
    	}
    }
	.close {
		top: 4px;
		right: -4px;
		width: 8px;
		fill: $lg;
		z-index: 9999;
	}
	h3, .h3 {
		font-family: $f;
		font-weight: 600;
		line-height: 24px;
		margin: 0; 
		padding: 30px 0 32px 0;
	}
	.success_bg {
		position: relative;
		z-index: 1051;
	}
	.info {
		background: $lg_bg;
		padding: 12px 25px 12px 12px;
		width: 100%;
		overflow: hidden;
		margin: 0 0 40px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.img_product {
			background: $wh;
			padding: 5px;
			width: 80px;
			margin-right: 10px;

			img {
				max-width: 100%;
				height: auto;
				@include rounded-corners(10px);
			}
		}
		.name {
			font-size: 16px;
			line-height: 22px;
			margin-right: 10px;
			max-width: 220px;
		}
		.price {
			font-weight: 600;
			font-size: 16px;
			font-family: $f;
			display: flex;
			flex-direction: column;

			.price-new {
				font-size: 16px;
				line-height: 22px;
				white-space: nowrap;
			}	
			.price-old {
				font-size: 14px;
				line-height: 22px;
				white-space: nowrap;
				text-decoration: line-through;
			}			
		}
	}
	.all-cart {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 24px;
			span {
				font-weight: normal;
			}
	}
	.info-cart {
		a {
			padding: 0 16px;
			@include size(auto,44px);
		}
		.go_shop {
			display: inline-block;
			margin-right: 15px;
		}
		.go_cart {
			display: inline-block;
		}
	}
	#products {
		margin-bottom: 30px;
	}
	.product-list {
		.scroll {
			overflow: auto;
			max-height: 355px;
			/* width */
			&::-webkit-scrollbar {
			  width: 6px;
			  @include rounded-corners(10px);
			}

			/* Track */
			&::-webkit-scrollbar-track {
			  background: #f1f1f1;
			  @include rounded-corners(10px);

			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
			  background: $tc;
			  @include rounded-corners(10px);

			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
			  background: $tc;
			}
		}
		.product-block {
			background: $lg;
			@include rounded-corners(10px);
			padding: 12px 25px 12px 12px;
			width: 99%;
			overflow: hidden;
			margin: 0 0 8px 0;
			border:0;
			 .block-img {
				width: 80px;
				margin-right: 15px;
				.image {
					border: 0;
					a {
						height: auto;
					}
					img {
					max-width: 100%;
					height: auto;
					@include rounded-corners(10px);
					}
				}
			 }
			 .title_p {
				width: 100%;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				a {
					font-weight: normal;
					font-size: 16px;
					line-height: 22px;
					color: $bk;
				}
			 }
			 .price {
				span {
					display: inline-block;
					&.price-new {
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						color: $bk;
					}
					&.price-old {
						font-weight: 600;
						font-size: 14px;
						line-height: 22px;
					}
				}
				.btn-cart {
					padding: 0;
					background: none;
					border: 0;
					color: $tc;
					display: block;
					margin: 0;
					text-align: left;
					font-size: 16px;
					line-height: 20px;
					@include size(100%,auto);
				}
			 }
		 
		}
	}
}
.success.compare {
	top:2%;
	.success_button {
		padding: 9px 16px;
		width: auto;
	}
	.text {
		margin-bottom: 50px;
	}
	.info-cart{
		display: flex;
		justify-content: space-between;
	}
}
.modal.bg {
	background: black;
	display: block !important;
	@include opacity(0.5);
	z-index: 0;
}
#callback_form {
	.control-label {
		display: none;
	}
	.modal-header {
		padding: 0 24px;
		border: none;
		.h2 {
			font-family: $f;
			font-weight: 600;
			line-height: 24px;
			margin: 0;
			padding: 30px 0 25px 0;
			font-size: 20px;
		}
	}
	.callback_button {
		float: none !important;
		margin: 15px 0 30px 0;
		text-transform: none;
	}
}
#finded-low-cost-form {
	.btn-primary {
		height: 44px;
		line-height: inherit; 
	}
}
#finded-low-cost-form, #reserve_form, #request-on-order-form {
	.close{
		padding: 13px 22px;
		box-sizing: content-box;
	}
}
#reserve_form, #request-on-order-form {
	.close{
		box-sizing: content-box;
		right: -24px;
		top: -10px;
	}
}
#finded-low-cost-form {
	.close{
		cursor: pointer;
		right: 0;
		top: 0;
	}
	.btn-primary {
		display: inline-block;
	}
}
.modal .modal-dialog, #form-review-container {
	
		.modal-content, .form-horizontal {
			h3 {
				margin: 0 0 24px 0;
				padding: 0;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
			}
			.form-control {
				@include rounded-corners(4px);
				font-size: 16px;
				line-height: 22px;
				color: $lg;
				padding: 16px;
				border: solid 1px $lg_bg;
			}
			textarea {
				max-height: 150px;
			}
			.buttons {
				.text-center {
					text-align: left;
				}
			}
		}

}
.reserve_form {
	.success_bg {
		padding: 10px 25px;
		.modal-body{
			padding: 0;
		}
	}
	.text_reserv {
		border-top: solid 1px $lg2;
		font-size: 16px;
		padding-top: 8px;
		margin-top: 40px;
		p {
			margin-bottom:0;
			svg {
				margin-right: 7px;
			}
		}
		p:last-child {
			margin-top:8px;
		}
	}
}
#buy_form_container {
	background: $wh;
	padding: 24px 24px 0 24px;

	.buy_products {
		.shopping-cart-table {
			background: none;
		}
		.pr_cart {
			width: 100%;
			border-top: solid 1px $lg2;
			padding: 8px 0 24px 0;
			display: flex;
			justify-content: space-between;
			&:first-child {
				border-top: none;
				padding: 0 0 24px 0;
			}
			.column-image {
				img {
					max-width: 80px;
					border: none;
					padding: 0;
				}
				.sale {
					position: absolute;
					left: 0;
					top: 0;
					padding: 0 6.5px;
				}
			}
			.name_price {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin-left: 8px;
				a {
					color: $lg;
					&:hover, &:focus, &:active {
						color: $tc2;
					}
				}
				.column-name {
					display: flex;
					flex-direction: column;
					margin-bottom: 24px;
					a {
						width: 380px;
						margin-right: -135px;
					}
				}
				.weight, .column-price {
					color: $mg;
					font-size: 12px;
				}
				.column-total span {
					font-weight: 600;
				}
			}
			.quant_clear {
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				justify-content: space-between;
				& > button {

				}
			}
			.btn-white {
				border: none;
				background: none;
				padding: 0;
				@include size(auto,auto);

				svg {
					path {
						fill:$lg;
					}
				}
			}
			.img_name {
				display: flex;
				.column-image {
					position: relative;
				}
			}
			.column-quantity{
				.qty {
					display: flex;
					flex-wrap: nowrap;
				}
				button {
					background: none;
					padding: 0;
					border: none;
					width: 24px;

					svg {
						path {
							fill: $tc;
						}
					}
				}
				input {
					font-weight: 500;
					font-size: 18px;
					color: $grey;
					border: none;
					padding: 0;
					width: 77px;
					margin: 0 10px;
					max-width: 50px;
				}
				svg {
					@include size(9px,9px);
				}
			}
		}
		.container {
			background: $wh;
			margin-bottom: 8px;
			@include rounded-corners(10px);
			padding-top: 15px;
			padding-bottom: 15px;

			.column-image {
				a {
					display: block;
					width: 80px;

					img {
					height: auto;
					border: 0;
					padding: 0;

					}	
				}
				
			}
			.column-name {
				padding-left: 0;
				a {
					display: block;
					text-decoration: none;
					font-size: 16px;
                    line-height: 22px;
                    color:$bk;
                    &:hover {
                    	color: $tc;
                    	text-decoration: none;
                    }
				}
			}
			.column-quantity {
				padding: 0;

				.remove {
					@include size(34px,34px);
					@include rounded-corners(10px);
					background: $wh;
					color: $tc; 
					border: 1px solid $tc;

					&:hover {
						background: $tc;
						color: $wh; 	
					}
				}
				.add {
					@include size(34px,34px);
					@include rounded-corners(10px);
					background: $tc;
					color: $wh; 
					border: 1px solid $tc;

					&:hover {
						background: $wh;
						color: $tc; 	
					}
				}
				.quanity {
					border:0;
					text-align: center;
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					color: $bk;
					width: 34px;
					padding: 0;
				}
			}
			.column-total {
				span {
					display: block;
					font-weight: 600;
					font-size: 16px;
					line-height: 22px;
					color:$bk;
					&.price_prod {
						margin: 0;
						color: $lg2;
						font-weight: normal;
					}
				}
			}
			.column-delete {
				vertical-align: top;
				button {
					padding: 0;
					margin: 0;
					border:0;
					height: auto;
					line-height: normal;
					font-size: 14px;
				}
			}
		}
		.product-deleted {
			display: flex;
			flex-wrap: nowrap;
			margin: 0 0 24px 0;
			padding-top: 8px;
			border-top: 1px solid $lg2;

			.column-image {
				margin-right: 8px;

				img {
					max-width: 80px;
					border: 0;
					padding: 0;
				}
			}
			.column-name {
				padding-right: 0;

				.name {
					color: $lg;
				}
			}
			.attention {
				color: $tc3;
				margin-bottom: 8px;
			}
			.btn-restore {
				margin-top: 16px;
				display: flex;
				font-weight: 600;
				line-height: 20px;

				a {
					color: $lg;
				}
				svg {
					margin-right: 8px;
					@include size(20px,20px);
				}
			}
		}
	}

}

.buy_form_container_right {
	background: $lg;
	@include rounded-corners(10px);
	padding: 24px 24px 24px 24px;
	#coupon_form {
		margin: 0 0 16px 0;
		h4 {
			margin: 0 0 16px 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
		}
		.form-group {
			position: relative;
			width: 100%;
			.form-control {
				position: relative;
				z-index: 0;
				@include size(90%,44px);
				padding: 11px 12px;
				@include rounded-corners(10px);
				border:0;
				color: $bk;
				font-size: 16px;
				line-height: 22px;
			}
			#button-coupon {
				position: absolute;
				right: 0;
				top:0;
				z-index: 1;
				background: none;
				color: $wh;
				font-weight: 600;
				font-size: 16px;
				line-height: 44px;
				height: 44px;
				padding: 0 25px;

			}
		}
	}
}

#total_info {
	margin-top: 30px;
	padding-top: 8px;
	border-top: 1px solid $lg2;
	table {
		border: 0;
		margin: 0;
		&:not(.top-sticky) {
			width: 100%!important;
		}
		&.top-sticky {
			margin-top: 15px;
			z-index: 1;

			&:after {
				content: '';
				@include size(calc(100% + 48px), calc(100% + 40px));
				display: block;
				background: $lg;
				@include rounded-corners(10px);
				padding: 24px 24px 24px 24px;
				top:-15px;
				left: -24px;
				z-index: -1;
				position: absolute;
			}
		}
		td {
			color: $bk;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			border:0;
			padding: 0 0 8px 0;
			strong {
				font-weight: normal;
				color: $lg;
			}

		}
		tr {
			display: flex;
			justify-content: space-between;
			&:nth-last-child(2) {
				margin-top: 8px;
				padding-top: 16px;
				border-top: 1px solid $lg2;

			}
			&:last-child {
				td {
					font-weight: 600;
					font-size: 20px;
					line-height: 24px;
					color: $bk;
					padding-bottom: 0;
					strong {
						font-weight: 600;
						color: $bk;
					}
					&:last-child {
						white-space: nowrap;
					}
				}
			}
			&#total ~ #shipping {
				td {
					padding-bottom: 16px;
				}
			}
			&#coupon {
				& ~ #shipping {
					td {
						padding: 0 0 16px 0;
					}
				}
				& + #total {
					border: none;
					td {
						padding: 0;
					}
				}
			}
		}
	}
}
.checkout_cart {
	display: flex;
	justify-content: space-between;
	position: relative;

	* {
		@include transition(0.2s all);
	}
	.order2  {
		width: 58%;
		max-width: 955px;
	}
	.order  {
		position: absolute;
		right: 0;
		max-width: 534px;
		width: 100%;

		.vue-sticky-placeholder {
			@include transition(0.5s easy);
		}
	}
	.order1 {
		@include rounded-corners(4px);
		z-index: 9;

		#coupon_form {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-top: 16px;

			label {
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
				width: 100%;
				border: 1px solid $lg;
				@include rounded-corners(8px);
				padding: 4px 0 4px 16px;
				margin-bottom: 0;
				color: $lg;
				margin-right: 16px;

				& > span {
					font-weight: 300;
					font-size: 12px;
					line-height: 14px;
					margin-top: -12px;
					display: block;
					width: max-content;
					background: $lg_bg;
					padding: 0 4px;
				}
			}
			.form-group {
				display: flex;
				justify-content: space-between;

				&:first-child {
					margin-bottom: 16px;
				}
			}
			b {
				margin-bottom: 8px;
			}
			#input-coupon {
				@include size(100%, 46px);
				background: transparent;
				@include box-sizing(border-box);
				@include rounded-corners(4px);
				padding: 12px 0;
			}
			#button-coupon{
				width: 30%;
				max-width: 147px;
				height: auto;
			}
		}
	}
	.checkbox_custom {
		.input-control {
			line-height: 22px;
		}
	}
}
#checkout-form {
	padding-bottom: 100px;

	.edit_fields,
	.pay_comm,
	.shipping_block,
	#fields_form,
	#button_edit_form {
		@include transition(0.2s all);
	}
	input:focus, input:active {
		background:$wh;
	}
	.indicators {
		position: relative;
		width: 100%;
		display: flex;
		justify-content:space-between;

		hr {
			position: absolute;
			left: 0;
			bottom: 11px;
			z-index: -1;
			width: 100%;
			border-color: $grey;
			margin: 0;
		}
		.steps {
			width: 100%;
			display: flex;
			align-items:center;
			justify-content:space-between;

			div {
				display: flex;
				flex-direction: column;
				align-items:center;
				justify-content:center;
				font-weight: 600;
				width: 33.3333%;
				line-height: 20px;

				span {
					border: solid 1px $grey;
					@include rounded-corners(50%);
					@include size(24px,24px);
					margin-top: 8px;
					font-weight: normal;
					line-height: 1px;
					background: $body-bg;
					display: flex;
					align-items:center;
					justify-content:center;
					padding: 4px;

					i {
						background: $grey;
						@include rounded-corners(50%);
						@include size(8px,8px);
					}
				}
				&.active {
					span {
						border-color: $tc;

						i {
							background: $tc;

							&.active {
								@include size(24px,24px);
								margin: 0 -8px;
								color: $wh;

								&:before {
									margin: 5px auto 0 auto;
									display: block;
									width: max-content;
									font-size: 12px;
								}
							}

						}
					}
				}
			}

			.step_1 {
				align-items: start;
			}
			.step_3 {
				align-items: flex-end;
			}
		}
	}
	.w1 {
		max-width: 557px;
	}
	.w2 {
		max-width: 414px;
	}
	.w3 {
		max-width: 701px;
	}
	.button_red_inv {
		width: 271px;
		margin-top: 56px;
		background: $tc;
		color: $wh;
	}
	.form-group {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}
		& > label {
			font-weight: 600;
			font-size: 16px;
			line-height: 22px;
			width: 100%;
			border: 1px solid $lg;
			@include rounded-corners(8px);
			padding: 4px 0 4px 16px;
			margin-bottom: 0;
			color: $lg;

			& > span {
				font-weight: 300;
				font-size: 12px;
				line-height: 14px;
				margin-top: -12px;
				display: block;
				width: max-content;
				background: $lg_bg;
				padding: 0 4px;
			}
		}
	}
	h3 {
		font-weight: 600;
		font-size: 20px;
		margin: 80px 0 48px 0;
		font-family: $f;
	}
	.col-sm-6 {
		float: none;
		overflow: hidden;
		clear: both;
	}
			.radio_custom {
			width: auto;
			display: inline-block;

			input,
			.input-control__indicator {
				display: none;
			}
			label {
				padding: 0;
				margin: 0;
				span {
					text-transform: none;
				}
			}
			input:checked ~ span {
				background: $tc;
				color: $wh;
			}
		}
	input.form-control {
		position: relative;
		background: transparent;
		padding: 12px 4px;
	}	
	.text-danger {
		@include opacity(0.8);
		padding: 0 10px;
		margin: 8px 0;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
	}
	.checkbox_custom .active .input-control__indicator {
		background: $bk;
		border-color: $bk;
	}
	.comment_block {
		max-width: 844px;
		h3{
			margin-bottom: 32px;
		}
	}
	.filled_fields {
		margin-bottom: 52px;
		.field {
			color: $lg;
			margin-bottom: 8px;
			&.not_fil {
				color: $tc;
			}	
			span {
				color:$bk;
			}
		}
	}
	.edit {
		border-bottom: solid 1px $lg2;
		padding-bottom: 8px;
		display: flex;
		justify-content:flex-end;
		.button {
			cursor: pointer;
			font-weight: 600;
			display: flex;
			align-items:center;
			svg {
				margin-left: 5px;
				path {
				}
			}
			&:hover, &:focus, &:active b{
				color:$tc2;
				svg path{
					fill: $tc2;
				}
			}
		}
	}
	.button_confirm {
		margin-top: 32px;
		width: 191px;
	}
}
.confirm_block {
	padding-top: 56px;

	.checkbox_custom {
		display: flex;

		label {
			margin: 8px 0;	
			display: inline-block;
			span.input-control__indicator {
				top:-4px;
			}
		}
		span.input-control__indicator + span {
			display: contents;
		}
		&.uniteller-modal {
			label {
				padding: 0;
			}
		}
	}
}

#shipping_methods,
#payment_methods {
	margin: 80px 0 0 0;

	.radio_custom {
	label {
		text-transform: none;
		font-size: 16px;
		margin: 0 8px 8px 0;

		span {
			display: block;
			padding: 11px 16px;
			@include rounded-corners(4px);
		}
		&:hover,
		&:focus,
		&:active {
			color:$wh;
		}
	}
  }
  .adress_block {
  	display: flex;
  	justify-content: space-between;
  	flex-wrap: wrap;
  	.form-group{
  		width: 100%;
  		margin-bottom: 16px;
  		padding-right: 16px;
  		&:first-child {
  			width: 100%;
  		}
  	}
  }
  .flat_tkflat_tk {
	  .adress_block{
		  .form-group{
			  width: 100%;
		  }
	  }
  }
  p {
  	max-width: 696px;
  	margin: 16px 0;
  }
}
#payment_methods {
	a {
		width: 100%;
		display: block;
		padding: 10px 0;
	}
	.radio_custom {
		label {
			.button_lg {
				font-weight: 400;
			}
		}
	}
}
.pay-nal,
.pay-nal2,
#pay-other,
.pay-install {
	padding: 12px 16px;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: $bk;
	margin: 2px 16px 16px 2px;
	text-transform: none;
	@include rounded-corners(10px);
	display: block;
	background: $lg;

	&:hover,
	&.active {
		background: $tc;
		color: $wh;
		cursor: pointer;
		text-decoration: none;
	}
}
.tk-comment {
	margin: 32px 0;

	p {
		margin: 0;
		padding: 0 0 5px 0;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
	}
}
.tk-type {
	margin: 5px 0;
	display: none;
}
#online-pay, #online-pay2 {
	float: none;
	width: 100%;
	clear: both;
	margin: 16px 0 0 0;
	padding-top: 16px;
	border-top: 1px solid $lg;
	display: none;
}
#comment-cart {
	margin-top: 55px;
	margin-bottom: 50px;

	.form-control {
		position: relative;
		@include size(100%,104px);
		padding: 11px 12px;
		background: $lg3;
		@include rounded-corners(10px);
		background: $lg;
		color: $lg2;
		border-color: transparent;
		font-size: 16px;
		line-height: 22px;
	}	
}
#button-order {
	font-size: 18px;
	height: 54px;
	line-height: 54px;
}
.information-direktoru {
	#input-topic {
		padding: 0;
		li {
			list-style: none;
		}
	}
	.form-horizontal .radio {
		padding-top: 0;
	}
	.form-control {
		@include rounded-corners(4px);
		padding: 11px;
		font-size: 16px;
		line-height: 22px;
		color: $lg2;
	}
	.radio_custom .input-control__indicator {
		border:1px solid $tc;
		background: $wh;
	}
}
.mega-hidden {display:none!important;}
.sidebar-offcanvas {
	display: none;
}
.row-offcanvas.active {
	#page {
		& > div:not(.sidebar-offcanvas),
		#header-layout,
		#footer {
			display: none;
		}
	}
	.sidebar-offcanvas {
		display: block;
		@include transform(translate(0, 0));
	}
}
.credit_product {
	width: 100%;
	float: none;
	margin-bottom: 8px;
	a {
		float: left;
		margin-right: 9px;
		font-weight: 500;
		line-height: 20px;
		color: $lg;
		&:hover {
			color: $tc;
			text-decoration: none;
		}
	}
	.credit_info {
		float: left;
		position: relative;
		svg {
			vertical-align: top;
			margin-top: 2px;
		}
		.credit_info_text {
			display: none;
			width: 304px;
			padding: 16px;
			background: $wh;
			@include box-shadow(0 8px 24px rgba(0, 0, 0, 0.08));
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $bk;
			position: absolute;
			top:36px;
			z-index: 1;
			left: -144px;
			&:after {
				content: '';
				@include size(37px,37px);
				display: block;
				background: $wh;
				position: absolute;
				top: -10px;
				left: 50%;
				margin-left: -18px;
				@include transform(rotate(45deg));
				z-index: -1;
			}
		}
		&:hover,
		&:focus,
		&:active,
		&.active {
			.credit_info_text {display: block;}
		}
	}
}
.success {
	.success_button {
    	margin-bottom: 20px;
	}
}
#request-on-order-form {
	padding: 0;
	top: 0;
}
.product-view {
	h1 {
		padding: 0;
		margin: 16px 0;
		line-height: 40px;
	}
}

.modal_div {
	background-color: rgba(0, 0, 0, 0.5);

	.h3 {
		display: inline-block;
	}
	form {
		padding-bottom: 30px;
	}
}
.product-search {
	#button-search {
		font-size: 18px;
		line-height: initial;
		height: 44px;
	}
	.checkboxes {
		display: flex;

		.checkbox_custom {
			margin: 24px 16px 24px 0;
		}
	}
	h2 {
		margin: 0;
		padding-bottom: 50px;
	}
}
body {
	&.page-error-not_found {
		#content {
			padding: 0;
		}
	}
	&.information-direktoru {
		#content,
		.main-columns.container,
		.main-wrap {
			padding: 0;
		}
	}
}
.kit_block + .related_pr,
.related_pr + .content_bottom_pr {
	padding-top: 0;
}

.related_pr {
	padding-top: 80px;

	.nav-tabs {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		li {
			margin-right: 8px;
			background: $wh;
			@include rounded-corners(2px);

			a {
				color: $lg;
				padding: 11px 16px;
			}
			&:last-child {
				margin-right: 0;
			}
			&.active {
				background: $tc;

				a {
					color: $wh;
				}
			}
		}
	}
	.go_cat {
		display: inline-block;
		padding: 11px 16px;
		width: max-content;
		margin-top: 32px;
		color: $tc;
		font-size: 16px;

		&:hover,
		&:focus,
		&:active {
			color: $tc2;
		}
	}
}
.content_bottom_pr {
	background: $gray-light2;

	&.none_kit {
		background: transparent;
	}
}
body{
	header, footer {
		position: relative;
		z-index: 21;
	}
	#page {
		overflow: hidden;
	}
}
.h1_container {
	position: relative;
	h1 {
		span {
			font-weight: normal;
			font-size: 16px;
			color: $lg;
			margin-left: 16px;
		}
	}
}
.checkout-buy {
	.order2 {
		padding-right: 32px;
	}
}
body.page-product header {
	z-index: auto;
}
.product-allproduct {
	.refine-search {
		margin: 0;
	}
}
 .menutoggle {
 	position: absolute;
 	right: 0;
 	top: 0;
 	z-index: 999;
 	padding: 20px 28px;

 	svg {
 		@include size(14px,14px);
 		fill:$txt_black;

 	}
 }
.search_button {
	display: none;
	svg {
		@include size(32px,32px);
	}
}
.bg_search {
	display: none;
	position: fixed;
	left: -100%;
	top:0;
	z-index: 99;
	@include size(100%,100%);
	background: rgba(0, 0, 0, 0.7);
	@include transition(all 0.3s  ease-in-out);
	&.active {
		left: 0;
	}
	.close {
		padding: 7px;
		box-sizing: content-box;

		svg {
			path{
				fill: white;
			}
		}
	}
}
.harakteristiki-list {
	padding-top: 8px;
	background: $wh;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	z-index: 1;
	ul {
		display: flex;

		li {
			margin-right: 16px;

			&:last-child {
				margin-right: 0;
			}
			&.active {
				a {
					border-bottom: solid 2px $tc;
					color: $tc;
				}
			}
		}
		a {
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			color: $lg;
			white-space: nowrap;
			padding: 12px 16px 14px 16px;

			&:hover,
			&:focus {
				border-bottom: solid 2px $tc;
				color: $tc;
			}
		}
	}
	.download-tab {
		display: none;
	}
	.nav-product-info {
		.info {
			display: none;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
			padding: 12px 0;

			.product-in-cart {
				&__qty {
					padding: 0;
					font-weight: 400;
					color: $txt_black;
				}
			}
			.btn {
				height: auto;
				padding: 0 8px;

				#button-cart {
					min-height: 44px;
					width: auto;

					span {
						font-size: 16px;
						line-height: 20px;
						font-weight: 600;
						padding: 12px 16px;
					}
				}
			}
			.price {
				margin-right: 8px;
				min-width: 77px;
			}
			.img {
				max-width: 101px;
				margin-right: 10px;
			}
		}
	}

	&.is_stuck {
		@include box-shadow(0 0 12px rgba(51, 51, 51, 0.1));
		width: 100% !important;
		left: 0;
		padding: 0 108px;
		display: flex;
		align-items: center;

		.nav-product-info {
			.info {
				display: flex;
			}
		}
	}
}
.filtr_toggle {
	font-size: 18px;
	padding: 8px;

	.count-filters {
		margin-left: 5px;
	}
	svg {
		margin-left: 8px;

		path {
			fill:$tc;
			stroke:none;
		}
	}
}
.information-contact {
	.main-columns.container {
		#content {
			padding: 0 0 50px 0;
		}
	}
	.btn-primary {
		width: auto;
		padding: 0 16px;
	}
}
.on_filter{
	position: fixed;
	overflow: hidden !important;
	margin: 0;
}

/* Slider */

.slick-slider {
	position: relative;
	display: block;
	@include box-sizing(border-box);
	@include user-select(none);
	-webkit-touch-callout: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
} 

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	@include transform(translate3d(0, 0, 0));
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
		width: 100%;
		max-height: inherit;
		max-width: inherit !important;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
	}
}

.slick-arrow {
	position: absolute;
	z-index: 10;
	top: 50%;
	border: none;
	outline: none;
	padding: 0;
	color: $wh;
	background-color: transparent;
	font-size: 26px;
	cursor: pointer;
	@include transform(translateY(-50%));
	@include transition(0.2s all);

	&.slick-prev {
		left: -30px;

		i {
			display: inline-block;
			@include transform(rotate(180deg));
		}
	}
	&.slick-next {
		right: -30px;
	}
	&.slick-hidden {
		display: none;
	}
	&:hover {
		color: $wh;
	}
	&:active {
		@include transform(translateY(calc(-50% + 3px)));
	}
}

//Dots
.slick-dots {
	position: absolute;
	bottom: 20px;
	left: 50%;
	display: flex;
	@include transform(translateX(-50%));

	li {
		margin: 0 8px;
	}

	button {
		display: block;
		@include size(10px,10px);
		padding: 0;
		@include rounded-corners(50%);
		border: 2px solid rgba(255,255,255,.5);
		background: $wh;
		text-indent: -9999px;
		@include transition(0.2s all);
	}

	.slick-active {
		button {
			background: $wh;
		}
	}
}
