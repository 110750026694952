.nav {
	margin-bottom: 0;
	padding-left: 0; // Override default ul/ol
	list-style: none;
	@include clearfix;

	> li {
		position: relative;
		display: block;

		> a {
			position: relative;
			display: block;
			padding: $nav-link-padding;
			&:hover,
			&:focus {
				text-decoration: none;
				// background-color: $gray-dark;
			}
		}
		&.disabled > a {
			color: $nav-disabled-link-color;

			&:hover,
			&:focus {
				color: $nav-disabled-link-hover-color;
				text-decoration: none;
				background-color: transparent;
				cursor: $cursor-disabled;
			}
		}
	}

	// Open dropdowns
	.open > a {
		&,
		&:hover,
		&:focus {
			background-color: $nav-link-hover-bg;
			border-color: $link-color;
		}
	}
	.nav-divider {
		@include nav-divider;
	}
	> li > a > img {
		max-width: none;
	}
}

// Tabs
// -------------------------

.nav-tabs {
	margin-bottom: 0;
	 .item {
		float: left;
		list-style: none;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
		&.active:before {
			/* border-top-color: $gray-dark; */
		}
		 a {
			line-height: $line-height-base;
			color: $lg;
			font-size: 20px;
			padding: 12px 8px;
			margin-right: 15px;
			@include transition(all 0.2s);
			&:hover {
				color: $bk;
			}
		}

		&.active  a {
			&,
			&:hover,
			&:focus {
				cursor: default;
				color: $bk;
				border-bottom: solid 1px $bk;
			}
		}
	}

	// pulling this in mainly for less shorthand
	&.nav-justified {
		@extend .nav-justified;
		@extend .nav-tabs-justified;
	}
}

.tab-content {
	border: 1px solid transparent;
}
.harakteristiki {
	position: relative;
	padding-top: 64px;
	padding-bottom: 54px;
	background: $wh;
}
.tabs_block {
	width: 65%;
	margin-right: 24px;
	padding-top: 56px;

	.downloads-content {
		display: none;
	}
	.tab-item {
		margin-bottom: 104px;

		&:last-child {
			margin-bottom: 40px;
		}
	}
	.title {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 24px;
	}
	.table {
		display: flex;
		flex-direction: column;

		.feat_one {
			line-height: 24px;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}
		.feature {
			.text {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 24px 0 16px 0;
				border-bottom: solid 1px $lg2;
				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}
				&:first-child  {
					padding-top: 0;
				}
				div {
					width: 100%;
					padding-right: 40px;
				}
			}
			.text.name_none {
				div {
					width: 100%;
					padding-right: 0;
				}
			}
		}
	}
}
#review {
	.title {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 24px;
	}
	.btn-primary {
		width: 187px;
	}
	.review{
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		padding: 32px 40px 40px 32px;
		background: $lg_bg;
		border-radius: 2px;
	}
	.date {
		color: $lg;
		font-weight: 400;
		line-height: 14px;
		font-size: 12px;
		margin-bottom: 4px;
	}
	.author {
		font-weight: 500;
	}
	.description {
		font-size: 16px;

		*:last-child {
			margin:0;
		}
		.name_desc {
			font-weight: 600;
		}
	}
	.bottom {
		display: flex;

		.rating {
			display: flex;
			align-items: center;
			margin-left: 16px;

			.rating_ico {
				padding-right: 5px;

				path {
					fill:$tc;
				}
			}
			.rat_count {
				font-size: 12px;
				color: $tc;
				font-weight: 300;
			}
		}
	}
}
// Pills
// -------------------------
.nav-pills {
	> li {
		float: left;

		// Links rendered as pills
		> a {
			font-weight: 800;
			text-transform: uppercase;
			border-radius: $nav-pills-border-radius;
		}
		+ li {
			margin-left: 2px;
		}

		// Active state
		&.active > a {
			&,
			&:hover,
			&:focus {
				color: $nav-pills-active-link-hover-color;
				background-color: $nav-pills-active-link-hover-bg;
				border-bottom: 2px solid $brand-success;
			}
		}
	}
}

// Stacked pills
.nav-stacked {
	> li {
		float: none;
		+ li {
			//margin-top: 2px;
			margin-left: 0; // no need for this gap between nav items
		}
	}
}

// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
	width: 100%;

	> li {
		float: none;
		> a {
			text-align: center;
			margin-bottom: 5px;
		}
	}

	> .dropdown .dropdown-menu {
		top: auto;
		left: auto;
	}

	@media (min-width: $screen-sm-min) {
		> li {
			display: table-cell;
			width: 1%;
			> a {
				margin-bottom: 0;
			}
		}
	}
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
	border-bottom: 0;

	> li > a {
		// Override margin from .nav-tabs
		margin-right: 0;
		border-radius: $border-radius-base;
	}

	> .active > a,
	> .active > a:hover,
	> .active > a:focus {
		border: 1px solid $nav-tabs-justified-link-border-color;
	}

	@media (min-width: $screen-sm-min) {
		> li > a {
			border-bottom: 1px solid $nav-tabs-justified-link-border-color;
			border-radius: $border-radius-base $border-radius-base 0 0;
		}
		> .active > a,
		> .active > a:hover,
		> .active > a:focus {
			border-bottom-color: $nav-tabs-justified-active-link-border-color;
		}
	}
}

// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
	> .tab-pane {
		display: none;
		visibility: hidden;
	}
	> .active {
		display: block;
		visibility: visible;
	}
}

// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
	// make dropdown border overlap tab border
	margin-top: -1px;
	// Remove the top rounded corners here since there is a hard edge above the menu
	@include border-top-radius(0);
}
.downloads-content {
	position: absolute;
	right: 84px;
	top: 193px;
	max-width: 414px;
	padding: 32px;
	@include box-shadow(0 8px 24px rgba(0, 0, 0, 0.08));
	border-radius: 2px;

	.title {
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 24px;
	}
	ul {
		padding-left: 0;
		li {
			margin-bottom: 8px;
			list-style: none;

			&:last-child {
				margin-bottom: 0;
			}
			svg {
				min-width: 32px;
				height: 32px;
				margin-right: 8px;

				path {
					fill: $tc;
				}
			}
		}
	}
	a {
		display: flex;
	   	align-items: flex-start;
	   	color: $bk;

	   	&:hover,
		&:active,
		&:focus {
	   		color: $tc2;
	   	}
	}

}