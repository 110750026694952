@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-SemiBold.otf');
    src: url('/fonts/manrope/Manrope-SemiBold.otf?#iefix') format('embedded-opentype'),
    url('/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
    url('/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-Bold.otf');
    src: url('/fonts/manrope/Manrope-Bold.otf?#iefix') format('embedded-opentype'),
        url('/fonts/manrope/Manrope-Bold.woff2') format('woff2'),
        url('/fonts/manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-ExtraBold.otf');
    src: url('/fonts/manrope/Manrope-ExtraBold.otf?#iefix') format('embedded-opentype'),
        url('/fonts/manrope/Manrope-ExtraBold.woff2') format('woff2'),
        url('/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-ExtraLight.otf');
    src: url('/fonts/manrope/Manrope-ExtraLight.otf?#iefix') format('embedded-opentype'),
        url('/fonts/manrope/Manrope-ExtraLight.woff2') format('woff2'),
        url('/fonts/manrope/Manrope-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-Light.otf');
    src: url('/fonts/manrope/Manrope-Light.otf?#iefix') format('embedded-opentype'),
    url('/fonts/manrope/Manrope-Light.woff2') format('woff2'),
    url('/fonts/manrope/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-Medium.otf');
    src: url('/fonts/manrope/Manrope-Medium.otf?#iefix') format('embedded-opentype'),
    url('/fonts/manrope/Manrope-Medium.woff2') format('woff2'),
    url('/fonts/manrope/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('/fonts/manrope/Manrope-Regular.otf');
    src: url('/fonts/manrope/Manrope-Regular.otf?#iefix') format('embedded-opentype'),
    url('/fonts/manrope/Manrope-Regular.woff2') format('woff2'),
    url('/fonts/manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
