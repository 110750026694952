/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-md-min) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-right {
		right: 0;
	}
	.row-offcanvas-left {
		//left: 0;
	}
	.row-offcanvas-right
	.sidebar-offcanvas {
		right: -100%;
	}
	.row-offcanvas-left
	.sidebar-offcanvas {
		left: -100%;
	}

	.row-offcanvas-right.active {
		right: 100%;
	}
	.row-offcanvas-left.active {
		//left: 100%;
	}
	.row-offcanvas-left.active {
		.sidebar-offcanvas {
			left: 0;
		}
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		@include size(100%,100%);
		background: rgba(0, 85, 255, 0.2);
		z-index: 99;
		overflow: auto;

		ul, ol {
			display: flex;
			flex-wrap: wrap;

			.cat_all {
				background: $wh;
				padding: 60px 16px 38px 16px;
			}
			li {
				list-style: none;

				.title {
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
				}
				&.canvas-menu-list {
					.submenu {
						flex-direction: column;
					}
				}
			}
		}
		.megamenu {
			& > li {
				&:not(.canvas-menu-list) {
					display: none;
				}
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}
@media screen and (max-width: $screen-xs-max) {
	.sidebar-offcanvas {
		ul, ol {
			li {
				&:not(.canvas-menu-list) {
					display: block;
					width: 100%;
					padding-left: 16px;
					padding-right: 16px;
				}
			}
		}
		.megamenu {
			& > li {
				&:not(.canvas-menu-list) {
					display: block;
				}
			}
		}
	}
}
/*offcanvas-menu*/
#offcanvasmenu {
	padding-bottom: 32px;

	.nav .caret {
		border-bottom-color: $txt_black;
		border-top-color: $txt_black;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	li {
		.fa {
			color: $txt_black;
			font-size: 22px;
			line-height: 1;
		}
		.fa-minus-square::before {
			content: "\f28e";
		}
		.fa-plus-square::before {
			content: "\f2c2";
		}
		.fa-arrow-down::before {
			content: '\f110';
		}
		.fa-arrow-up::before {
			content: '\f113';
		}
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 0;
				position: absolute;
				right: 0;
				z-index: 1;
				transition: 0.3s ease;

				&.r90 {
					@include transform(rotate(180deg));
				}
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				font-weight: 600;
				line-height: 20px;
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $txt_black;
				padding-left: 15px;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		@include rounded-corners(0);
		@include box-shadow(none);
		display: none;
		float: none;
		margin: 0;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		background: $wh;
		padding: 0;

		div.title {
			color: $txt_black;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 16px;
			position: relative;
		}
		.mega-col {
			padding: 0;
		}
		.row {
			margin: 0;
		}
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			color: $text-color;
			&:hover {
				color: $theme-color;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	.cal_all {
		li {
			padding: 5px 20px;
		}
	}
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $tc;
	font-size: 28px;
	padding: 0 10px;
	border: none;
}