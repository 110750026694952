.category-description {
	margin: 25px 0 40px 0;
	background: $wh;
	padding: 24px;

	a {
		color: initial;
	}
}
.owl-carousel-play {
	.owl-carousel {
		border: none;
	}
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-weight: 400;
	font-family: $hf;
	.price-new {
		color: $bk;
		font-size: 22px;
	}
	&.detail {
		.price-new {
			font-size: 32px;
		}
		ul {
			margin: 0;
		}
	}
}

.cheap_invoice {
	a {
		font-size: 16px;
		margin-bottom: 5px;
		text-decoration: underline dotted;
		display: block;
		color: $txt_black;
		&:hover {
			color: $bk;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	border-top: 1px solid transparent;
	@include rounded-corners(4px);

	.product-attr {
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.product-meta {
		padding: 0;
	}
	.bottom {
		display: block;
	}
	.price {
		font-family: $f;
		display: flex;
		flex-direction: column;

		.price-old {
			color: $lg;
			font-weight: 400;
			font-size: 16px;
			text-decoration: line-through;
		}
		.price-new {
			font-size: 20px;
			font-weight: 600;
			&.red {
				color: $rd;
			}
		}
	}
	.price.no_stock .price-new{
		color:$lg;
	}
	.other-desc-right {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.rating {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			.rating_ico {
				padding-top: 1px;
			}
			svg {
				path{
					fill: $lg;
				}
			}
			&.active {
				svg path,
				.rat_count {
					color: $tc;
					fill: $tc;
					font-weight: 500;
				}
			}
			.rat_count {
				margin-left: 5px;
				color: $lg;
			}
			.rev_count {
				margin-left: 8px;
				color: $lg;
				font-weight: 500;
			}
		}
		.compare {
			cursor: pointer;
			svg {
				vertical-align: middle;
				@include size(20px,20px);
				path {
					@include transition(all .2s ease-in-out);
				}
			}
			.compare_button {
				&:hover,
				&:active,
				&:focus{
					svg path{
						fill: $tc2;
					}
				}
			}
		}
	}
	.name {
		margin-top: 7.5px;
		margin-bottom: 0;
		font-size: $font-size-base;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 600;
		a {
			color: $bk;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			@include transition (all 200ms ease);

			&:hover,
			&:active,
			&:focus{
				color: $tc2;
			}
		}
	}
	.block-img {
		position: relative;
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding-bottom: 8px;
	}
	.product-attr {

	}
	.description {
		font-size: 12px;
		line-height: 17px;
		padding-top: 10px;
		display: none;
	}
	.cart {
		.def_cart {
			cursor: pointer;
			display: flex;
			font-weight: 600;
			font-size: 18px;
			color:$grey;
			padding: 5px 0;
			width: unset;
			background: unset;

			svg {
				@include size(20px,20px);
				path {
					fill: $tc;
					@include transition (all 200ms ease 0s);
				}
			}
			&:hover,
			&:active,
			&:focus{
				path {
					fill: $tc2;
				}
			}
		}
	}
	.cart.no_stock .def_cart{
		padding: 0;
		min-width: 90px;
	}
}

.cart, .quantity_cart_add, .request-on-order-form {
	position: relative;
	@include transition(all 0.4s ease 0s);
	.icon-cart {
		display: block;
		@include size(16px, 17px);
	}
	.product-in-cart {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.cart-product-add {
			display: flex;
			svg {
				@include size(9px,9px);
			}
		}
		.cart-product-remove {
			display: flex;
			svg {
				@include size(9px,2px);
			}
		}
		.cart-product-remove, .cart-product-add {
			@include size(21px,21px);
			cursor: pointer;
			@include rounded-corners(50%);
			display: flex;
			align-items: center;
			justify-content: center;
			@include transition(all .2s);
		}
		.cart-product-quantity {
			font-weight: 600;
			font-size: 18px;
			color: $grey;
			padding: 0 15px;
		}
	}

}
.cart {
	.cart-product-remove,
	.cart-product-add {
		svg {
			path{
				@include transition(all .2s);
			}
		}
		&:active,
		&:focus,
		&:hover {
			svg {
				path{
					fill:$tc;
				}
			}
		}
	}
}


.success_bg .quantity_cart_add,
.request-on-order-form  {
	margin-right: 12px;

	.cart-product-remove,
	.cart-product-add {
		@include size(34px,34px);
		@include rounded-corners(4px);
		margin: 0;
		padding: 8px 8px;
	}
	.cart-product-remove {
		@include size(34px,34px);
		@include transition(all .2s);
		svg {
			path {
				fill:$tc;
				stroke:none;
			}
		}
		&:active,
		&:focus,
		&:hover {
			svg {
				path {
					fill:$wh;
				}
			}
		}
	}
}
.product-label {
	display: inline-block;
	float: left;
	padding: 4px 8px;
	line-height: 14px;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
	@include rounded-corners(24px);
	@include opacity(0.8);
	margin: 0 4px 0 0;

	&:last-child {
		margin-right: 0;
	}
	span {
		height: 100%;
		font-weight: 600;
		display: flex;
		align-items: center;

		svg {
			margin-right: 6px;
		}
	}
	&.sale {
		color: $wh;
		background: $tc;
	}
	&.new {
		color: $wh;
		background: #71AA1F;
	}
	&.sticker {
		position: absolute;
		bottom: 10px;
		left: 25px;
		padding: 0;
		@include opacity(1);
		margin: 0;
		@include rounded-corners(0);
		float: unset;

		svg {
			@include size(70px,70px);
		}
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 16px;
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;

	#tab-description {
		& > table {
			width: auto;
		}
	}
	.desc-header-wrapper {
		border: 1px $tc solid;
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			padding: 15px;
			color: $tc;
			text-transform: uppercase;
			font-size: 18px;
			position: relative;
			top: 1px;
			display: inline-block;
		}
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $bk !important;
		}
	}
	.owl-related {
		.owl-next, .owl-prev {
			top: -57px;
		}
		.owl-next {
			right: 18px;
		}
		.owl-prev {
			right: 48px;
		}
		.owl-stage {
			padding: 0;
		}
		.product-block {
			width: 100%;
			margin: 0;
			.image {
				border: none;
			}
		}
	}
	.widget-products {
		margin: 0 -2px;
	}
	.scroll-button {
		margin-bottom: 5px;
	}
	.product-grid {
		&.no-scroll {
			.product-block {
				margin: 4px 2px;
				width: calc(100% / 4 - 7px);

				.image {
					border: 0;
				}
			}
		}
	}
}

.cheap_invoice {
	margin-bottom: 15px;
}

.storage_widget {
	border-top-color: $bc;
	& > span:nth-child(2) {
		color: $theme-color !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:last-child span {
			background: $theme-color !important;
			@include rounded-corners(0 !important);
			&:hover {
				background: $bk !important;
			}
		}
	}
}
.kit_block{
	padding-top: 80px;
	padding-bottom: 80px;
	background: $gray-light2;

	.container {
		padding: 0;
	}
	.h1 {
		margin: 0 0 32px 0;
		font-size: 36px;
		line-height: 38px;
	}
	.producs_block {
		display: flex;
		flex-wrap: wrap;
		.producs_block_left  {
			display: flex;
			flex-direction: column;
			margin-right: 54px;
			width: 557px;
		}
		.plus, .total {
			align-self: center;
			width: 4%;
			text-align: center;
			font-size: 28px;
			font-weight: 400;
		}
		.product_list_item {
			display: flex;
			width: 100%;
			margin-bottom: 8px;
			padding: 4px 8px;
			&:last-child{
				margin-bottom:0;
			}
			.price {
				font-weight: 600;
				font-size: 16px;
			}
			.product-meta a {
				font-weight: 300;
			}
			.block-img {
				margin-right: 16px;
				.image {
					padding: 0;
					img {
						width: 64px;
					}
				}
			}
			.name {
				margin-bottom: 4px;
			}

		}
		.name a, .product-meta {
			margin: 0;
			line-height: 22px;
		}
		.kit_total {
			width: 376px;
			border: none;
			padding: 35px 40px;
			.btn-primary {
				@include size(100%,44px);
			}
			.price {
				.price-old {
					text-decoration: none;
				}
				div {
					display: flex;
					margin-bottom: 11px;
					&:last-child {
						margin-bottom:24px;
					}
					span {
						width: 50%;
						font-size: 16px;
						font-weight: normal;
						color: $bk;
					}
					span:last-child {
						text-align: center;
					}
					.title {
						font-weight: 600;
						font-size: 18px;
						line-height: 24px;
					}
				}
			}
		}

	}

}