body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
.nav-search {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	width: 100%;
	margin-right: 24px;

	.search_block {
		width: 100%;
	}
}
#searchtop {
	width: 100%;
	input.form-control {
		background: $lg_bg;
		color: $bk;
		border:none;
		font-size: 16px;
		height: 48px;
		padding: 13px 24px;
		@include rounded-corners(4px 0 0 4px);
		@include placeholder($bc);

		&::placeholder {
			color: $bk;
		}
	}

	.input-group-btn {
		background: $lg_bg;
		@include rounded-corners(0 4px 4px 0);
		@include transition(all 0.2s ease-in-out);
		&:hover {
			color: #000;
		}
	}
	.btn-search {
		cursor: pointer;
		background: none;
		@include rounded-corners(0 4px 4px 0);
		height: 44px;
		padding: 8px 24px 8px;
		svg {
			path{
				@include transition (all 200ms ease-in-out);
				fill: $tc;
			}
		}
		&:hover, &:focus, &:active {
			svg path {
				fill: $tc2;
			}
		}
	}
}

.form-control::-moz-placeholder {
	color: $bc;
}

// module cart
#cart, .product-compare-header {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: auto;
	padding: 0 8px;
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: flex;
		position: relative;
		text-decoration: none;
		svg {
			path{
				@include transition (all 200ms ease-in-out);
				fill: $tc;
			}
		}
		&:hover, &:focus, &:active {
			svg {
				path {
					fill: $tc2;
				}
			}
		}
	}
	.icon-cart {
		display: flex;
		padding: 0;
		height: auto;
		i {
			font-size: 36px;
			line-height: 28px;
		}
	}
	svg {
		width: 32px;
		height: 32px;
	}
	.wrap-cart, #compare-right {
		color: $bk;
		font-size: 12px;
		span {
			position: relative;
		}
		.badge {
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
			height: 18px;
			color: $white;
			position: absolute !important;
			top: -6px ;
			right: -4px;
			z-index: 1;
			background: $tc3;
			line-height: 18px;
		}
	}
	.price-cart {
		color: $tc;
	}
	.cart-inner {
		display: flex;
		text-align: right;
	}
}
/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}
