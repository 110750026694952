.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	> li {
		display: flex;
		align-items: center;
		white-space: nowrap ;
		span {
			padding: 0 7.5px;
			white-space: break-spaces;
		}
		a {
			color: $lg;
			&:hover, &:focus, &:active, &.active {
				color: $tc2;
			}
		}
		+ li:before {
			content: "";
			padding: 8px 5px;
			color: $text-color;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='17' viewBox='0 0 10 17' fill='none'%3E%3Cpath d='M0.745899 16.5089C0.44784 16.2167 0.420743 15.7595 0.66461 15.4373L0.745899 15.345L7.47342 8.75L0.745899 2.15503C0.44784 1.86283 0.420743 1.40558 0.66461 1.08338L0.745899 0.991064C1.04396 0.698865 1.51037 0.672302 1.83904 0.911374L1.93321 0.991064L9.2541 8.16802C9.55216 8.46022 9.57926 8.91746 9.33539 9.23967L9.2541 9.33198L1.93321 16.5089C1.60534 16.8304 1.07376 16.8304 0.745899 16.5089Z' fill='%23333333' style='&%2310; fill: %23717171;&%2310;'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center;
		}
		&:first-child {
			span {
				padding-left: 0;
			}
		}
		&:last-child {
			span {
				color: $lg;
			}
		}
	}
}