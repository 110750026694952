.paging {
	padding: 4px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pagination {
	display: inline-block;
	margin: 0;
	padding: 0;

	> li {
		display: inline; // Remove list-style and block-level defaults
		> a,
		> span {
			position: relative;
			float: left; // Collapse white-space
			margin-right: 8px;
			line-height: 32px;
			@include size(40px,34px);
			text-decoration: none;
			text-align: center;
			color: $pagination-color;
			font-size: $font-size-base;
		}
		&:first-child {
			> a,
			> span {
				margin-left: 0;
			}
		}
	}
	.pagination_arrows i{
		font-weight: 600;
	}
	> li > a,
	> li > span {
		@include size(56px,60px);
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		border-bottom: solid 2px $wh;
		cursor: pointer;
		color: $lg;
		@include transition(0.2s all);

		&:hover,
		&:focus {
			color: $tc;
			border-color: $tc;
			text-decoration: none;
		}
	}

	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			color: $tc;
			border-color: $tc;
			cursor: default;
			text-decoration: none;
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			color: $pagination-disabled-color;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border;
			cursor: $cursor-disabled;
		}
	}
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
	@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
	@include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
