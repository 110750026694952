.pav-megamenu {
	.navbar-nav {
		.canvas-menu-list {
			display: none;
		}
	}
}

// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.tparrows {}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $theme-color;
}

.effect-carousel-v2 {
	.carousel-controls-v1 {
		position: absolute;
		right: 0;
		.carousel-control {
			@include opacity(0);
			@include transition(all 0.5s ease-in-out 0s);
			top: -20px;
			&.left {
				left: -63px;
			}
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			top: 2px;
		}
	}
}

.effect-carousel {
	.carousel-control {
		@include opacity(0);
		@include transition(all 0.5s ease-in-out 0s);
		&.left {
			left: -45px;
		}
		&.right {
			right: -45px;
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}
		}
	}
}

/* tablist-v1 */
.tablist-v1 {
	.product-grid {
		.products-row {
			.product-col {
				&:last-child {
					border-right: 1px solid $border-color;
					@include rounded-corners(3px);
				}
			}
		}
	}
}

.homebuilder {
	.panel-title,
	.h2 {
		font-weight: 600;
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 0;
		margin-top: 0;
	}
}

.featured-category {
	margin-top: 104px;

	.popular-category-list {
		margin: 0;

		li {
			position: relative;

			.image {
				position: relative;
				z-index: 12;
				padding-bottom: 40px;
			}
			.caption {
				position: absolute;
				bottom: 0;
				z-index: 11;
				background: $wh;
				@include size(100%,132px);

				h6 {
					margin: 80px auto 32px auto;
					text-align: center;

					span {
						font-weight: 500;
						font-size: 16px;
						line-height: 20px;
						color: $txt_black;
						padding: 0 24px;
						display: block;
					}
				}
			}
		}
	}
}

.product-col-category {
	.product-col-cat-carousel {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 32px;

		.item {
			margin-right: 8px;

			.tab_cat {
				font-weight: 400;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.gift-certs {
	background-color: $wh;
	margin-top: 104px;
	margin-right: 108px;
	margin-left: 108px;
	padding: 96px 0 128px 96px;
	background-image: url(#{$cdn}img_template/gift-certs-bg.png);
	background-repeat: no-repeat;
	background-position-y: bottom;
	background-position-x: right;
	background-size: contain;

	h2 {
		font-weight: 600;
		font-size: 36px;
		line-height: 40px;
		margin: 0 0 32px 0;
	}
	.description {
		max-width: 606px;
		font-size: 24px;
		line-height: 32px;

		p {
			margin-bottom: 0;
		}
	}
	.link {
		margin-top: 24px;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		display: block;
	}
	svg {
		margin-left: 11px;
		height: 15px;

		path {
			stroke: $tc;
		}
	}
}
.page-category {
	.gift-certs {
		margin: 80px 0 0 0;
	}
}
.newsletter-container {
	margin-top: 104px;
	padding: 54px 0 56px 0;
	display: flex;

	.img {
		img {
			margin-top: -55px;
		}
	}
	.newsletter-block {
		max-width: 987px;
		margin-right: 32px;

		.title {
			margin-top: 0;
			margin-bottom: 40px;
		}
		.text {
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 32px;
		}
		svg {
			path {
				stroke: $grey;
			}
		}
		.tm-login-form {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			padding-bottom: 8px;
			border-bottom: 2px solid $grey;

			input {
				color: $grey;
				font-weight: 600;
				font-size: 32px;
				line-height: 40px;
				background-color: transparent;
				padding: 0;
			}
			button {
				width: auto;
				background: transparent;
			}
			svg {
				vertical-align: bottom;
			}
		}
		.checkbox_custom {
			margin-top: 32px;

			label {
				margin: 0;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
			}
			a {
				color: inherit;
				margin-left: 5px;
			}
		}
		.newsletter-error {
			color: $rd;
		}
		.newsletter-success,
		.newsletter-error {
			display: block;
			margin-top: 8px;
		}
	}
}