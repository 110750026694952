$cdn: 'https://cdn.sani-shop.ru/image/';

$bk: #333 !default;
$wh: #fff !default;
$gray2: #d9e3e6 !default;
$lg_bg: #F2F6FF !default;
$lg_gray: #E4E4E4 !default;
$lg_gray2: #EEEEEE !default;
$lg2_bg: #141414 !default;

$tc: #0055FF !default;
$tc2: #1F6AFFFF !default;
$tc3: #FF7A00 !default;

$txt_black: #011931 !default;

$gr: #64AA38 !default;
$rd: #F44336 !default;

$link-hover-color: $tc2;
$body-bg: $lg_bg;
$input-height-base: 54px;


$grey: #929292 !default;
$lg: #717171 !default;
$lg2: #C6C6C6 !default;
$lg3: #F5F5F5 !default;
$mg: #959595 !default;
$dg: $tc2 !default;
$ddg: #353536 !default;
$lb: #5c97bf !default;
$bc: #c4c4c4 !default;
$lbc: #ededed !default;
$b: #2d5cb8 !default;

$f: Manrope !default;
$hf: $f;
$fa: "FontAwesome" !default;


@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx.scss";

@import "app/custom";
@import "opencart/responsive";